import React, { useState, useEffect, useRef, useMemo } from "react";
import "./blog.scss";
import { Card, Form, Button, Col } from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import JoditEditor from "jodit-react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { useHistory } from "react-router-dom";

const AddBlog = (props) => {
  const imageBaseUrl = process.env.REACT_APP_API_BASE_IMAGE_URL;
  let access_token = localStorage.getItem("token");
  const history = useHistory();

  let BlogDesContent = "";
  if (props.keyword === "update") {
    BlogDesContent = props?.data?.description;
  }
  const [blogDescription, setBlogDescription] = useState(
    props?.data?.description
  );

  const [blogDescriptionExt, setBlogDescriptionExt] = useState(
    props?.data?.description_ext
  );
  const [title, setTitle] = useState(props?.data?.title);
  const [slug, setSlug] = useState(props?.data?.slug);
  const [metaTitle, setMetaTitle] = useState(props?.data?.meta_title);
  const [metaDescription, setMetaDescription] = useState(
    props?.data?.meta_description
  );
  const editor = useRef(null);
  const [selectedFile, setSelectedFile] = useState(false);
  const [attactment, setAttachment] = useState({
    name: "",
    value: "",
  });
  const [value, setvalue] = useState("");
  const [loader, setLoader] = useState(false);
  const [category, setCategory] = useState();

  const attachmentInputRef = useRef();
  const [error, setError] = useState({
    attachment: "",
    blogDescription: "",
    category: "",
  });

  const [options, setOptions] = useState();

  const handleOnchange = (val) => {
    setCategory(val);
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (
      blogDescription === "" ||
      blogDescription === null ||
      blogDescription === undefined
    ) {
      setError({ ...error, blogDescription: "Please enter description" });
      return false;
    }

    if (!props?.data) {
      if (category === null || category === undefined || category === "") {
        setError({ ...error, category: "Please select a category" });
        return false;
      }
    }
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      let formData = new FormData();

      if (props?.keyword === "update") {
        if (props.data.title !== title) {
          formData.append("title", title);
        }
        if (props.data.slug !== slug) {
          formData.append("slug", slug);
        }
        if (props.data.meta_title !== metaTitle) {
          formData.append("meta_title", metaTitle);
        }
        if (props.data.meta_description !== metaDescription) {
          formData.append("meta_description", metaDescription);
        }
        if (attactment?.value && attactment.name) {
          formData.append("image", attactment?.value, attactment?.name);
        }
        if (props.data.description !== blogDescription) {
          formData.append("description", blogDescription);
        }
        if (category) {
          const catArr = category.split(",");
          let i;
          for (i = 0; i < catArr.length; i++) {
            catArr[i] = Number(catArr[i]);
          }
          formData.append("category", JSON.stringify(catArr));
        }
      } else {
        formData.append("title", title);
        formData.append("slug", slug);
        formData.append("meta_title", metaTitle);
        formData.append("meta_description", metaDescription);
        formData.append("image", attactment?.value, attactment?.name);
        formData.append("description", blogDescription);
        const catArr = category.split(",");
        let i;
        for (i = 0; i < catArr.length; i++) {
          catArr[i] = Number(catArr[i]);
        }
        formData.append("category", JSON.stringify(catArr));
      }

      let response = await axios({
        method: `${props?.keyword === "update" ? "patch" : "post"}`,
        url: `${
          props?.keyword === "update"
            ? `${imageBaseUrl}/blog/blog/${props?.data?.slug}/`
            : `${imageBaseUrl}/blog/blog/`
        }`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;boundary=`---MyBoundaryIs123`",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response.data) {
        setLoader(false);
        toast.success("Your Post has been saved", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (props?.keyword !== "update") {
          attachmentInputRef.current.value = "";
          setSelectedFile(false);
          setAttachment({
            name: "",
            value: "",
          });
          setTitle("");
          setSlug("");
          setBlogDescription("<p></p>");
          setCategory("");
          setOptions("");
        } else {
          props.updatedata();
          props.hideModal();
        }
        history.push("/blog/all-blog");
      } else {
        setLoader(false);
        throw new Error(response.statusText);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const bannerDataInputClickHandler = (event) => {
    let parser = new DOMParser();
    const doc = parser.parseFromString(event, "text/html");
    let editorWrp = document.querySelector("#about-data-editor .jodit-wysiwyg");
    const childN = editorWrp.childNodes;
    let emptyParaLength = 0;
    if (childN.length === 1) {
      const findWrp = doc.getElementsByTagName("p")[0];
      if (findWrp.innerHTML !== "<br>") {
        setBlogDescription(event);
        setError({ ...error, blogDescription: "" });
      } else {
        setError({ ...error, blogDescription: "Please enter Description" });
      }
    } else {
      for (var i = 0; i < childN.length; i++) {
        if (childN[i].innerHTML !== "<br>") {
          emptyParaLength += 1;
        }
      }
      if (emptyParaLength >= 1) {
        setBlogDescription(event);
        setError({ ...error, blogDescription: "" });
      } else {
        setError({ ...error, blogDescription: "Please enter Description" });
      }
    }
  };

  const inputClickHandler = (event, val) => {
    const { name, value } = event.target;
    setvalue(val);
    switch (name) {
      case "attachment":
        if (value === "" || value == null || !value) {
          if (!attactment) {
            setError({ ...error, attachment: "can not be empty" });
          }
        } else {
          setError({ ...error, attachment: "" });
        }
        if (value) {
          setAttachment({
            name: event.target.files[0].name,
            value: event.target.files[0],
          });
          setSelectedFile(true);
        }
        break;
      case "title":
        if (value === "") {
          setError({ ...error, title: "Title can not be empty" });
        } else {
          setError({ ...error, title: "" });
        }
        setTitle(value);
        setSlug(value?.split(" ")?.join("-"));
        break;

      case "slug":
        if (value === "") {
          setError({ ...error, slug: "Slug can not be empty" });
        } else {
          setError({ ...error, slug: "" });
        }
        setSlug(value);
        break;

      case "metaTitle":
        setMetaTitle(value);
        break;
      case "metaDescription":
        setMetaDescription(value);
        break;
      case "category":
        if (value === "") {
          setError({ ...error, category: "category can not be empty" });
        } else {
          setError({ ...error, category: "" });
        }
        setCategory([...category, value]);
        break;
      case "description_ext":
        setBlogDescriptionExt(value);
        break;
      default:
        break;
    }
  };

  const getCategoryData = async () => {
    try {
      let response = await axios({
        method: "get",
        url: `${imageBaseUrl}/blog/category/`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response.statusText) {
        const arrayObj = response.data;
        let i;
        for (i = 0; i < arrayObj.length; i++) {
          arrayObj[i].value = arrayObj[i]["id"];
          delete arrayObj[i].id;
          arrayObj[i].label = arrayObj[i]["name"];
          delete arrayObj[i].name;
        }
        setOptions(response.data);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <h3>Add Blog</h3>
      </div>
      <Card border="light" className="bg-white shadow-sm mb-4 addblog_page_wrp">
        <Card.Body>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
              <Form.Label>Blog Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                name="title"
                required
                isInvalid={error.title}
                isValid={title}
                value={title}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Blog Slug</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter slug"
                name="slug"
                required
                isInvalid={error.slug}
                isValid={slug}
                value={slug}
                onBlur={() => {
                  setSlug(slug?.split(" ")?.join("-"));
                }}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Meta Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Meta title"
                name="metaTitle"
                required
                // isInvalid={error.title}
                // isValid={title}
                value={metaTitle}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Meta Description"
                name="metaDescription"
                required
                // isInvalid={error.title}
                // isValid={title}
                value={metaDescription}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>

            <Form.Label className="mt-2">Select Category</Form.Label>
            <div>
              <div className="preview-values d-flex gap-2">
                {props?.data?.category?.length &&
                  props?.data?.category.map((item, i) => {
                    return (
                      <span
                        className="bg-dark text-white my-2 px-1 rounded-1 text-capitalize"
                        key={item.id}
                      >
                        {item.name}
                      </span>
                    );
                  })}
              </div>
              <MultiSelect
                onChange={(e) => {
                  handleOnchange(e);
                }}
                options={options}
                value={category}
                name="category"
              />
            </div>
            <p style={{ color: "red" }}>{error.category}</p>
            <Form.Group>
              <Form.Label>Choose Blog Image</Form.Label>
              <Form.Control
                type="file"
                name="attachment"
                accept="image/png, image/jpeg"
                required={props?.data?.image ? false : true}
                ref={attachmentInputRef}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>
            {selectedFile ? (
              <div style={{ padding: "2px", margin: "2px" }}>
                <img
                  style={{
                    maxWidth: "60%",
                    border: "2px solid #ede7e7",
                    borderRadius: "10px",
                  }}
                  src={window.URL.createObjectURL(attactment?.value)}
                  alt="productImage"
                />
              </div>
            ) : (
              <img src={`${imageBaseUrl}${props?.data?.image}`} alt="" />
            )}

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Description"
                name="description_ext"
                required
                value={blogDescriptionExt?.replace(/<[^>]+>/g, "")}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>

            <Form.Group id="about-data-editor">
              <Form.Label>Content</Form.Label>
              <JoditEditor
                ref={editor}
                placeholder="Enter Detail"
                tabIndex={1}
                value={blogDescription}
                onBlur={(e) => setBlogDescription(e)}
                onChange={(e) => {
                  bannerDataInputClickHandler(e);
                }}
              />
              <p style={{ color: "red" }}>{error.blogDescription}</p>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddBlog;
