import React, { useState, useContext } from "react";
import SimpleBar from "simplebar-react";
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faFileAlt,
  faVoicemail,
  faSignOutAlt,
  faSitemap,
  faTimes,
  faCalendarAlt,
  faInbox,
  faHandshake,
  faDisease,
  faBlog,
  faClipboardList,
  faDatabase,
  faLocationArrow,
  faArrowRight,
  faArrowLeft,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import AtulyaHearingLogo from "../assets/img/AtulyaHearingLogo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-2.jpg";
import AuthContext from "../store/auth-context";

export default (props = {}) => {
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const history = useHistory();
  const onCollapse = () => setShow(!show);

  const logOutClickHandler = () => {
    authCtx.logout();
  };

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      onClick,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link
          onClick={onClick}
          {...linkProps}
          target={target}
          className={classNames}
        >
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.ContactUs.path}>
          <Image src={AtulyaHearingLogo} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={30} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, Admin</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                    onClick={logOutClickHandler}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {/* <Image src={AtulyaHearingLogo} className="navbar-brand-light" /> */}
              {/* <h5>
                <span>
                  <img
                    style={{ maxWidth: "10%" }}
                    src={AtulyaHearingLogo}
                    alt=""
                  />
                </span>
                Atulya Hearing
              </h5>
              {/* <NavItem title="Atulya Hearing" image={AtulyaHearingLogo} /> */}
              <br></br>
              <NavItem
                title="Dashboard"
                link={Routes.DashboardOverview.path}
                icon={faFileAlt}
              />

              <NavItem
                title="Banner Upload"
                icon={faImage}
                link={Routes.Banner.path}
              />

              <CollapsableNavItem
                eventKey="quick-screening/"
                title="Hearing Test"
                icon={faClipboardList}
              >
                <NavItem title="Add Question" link={Routes.ScreeningAdd.path} />
                <NavItem
                  title="Users Result"
                  link={Routes.UserScreening.path}
                />
                {/* <NavItem title="Add Category" link={Routes.Category.path} /> */}
              </CollapsableNavItem>

              <NavItem
                title="Partners"
                link={Routes.Partners.path}
                icon={faHandshake}
              />

              <CollapsableNavItem
                eventKey="products/"
                title="Products"
                icon={faSitemap}
              >
                <NavItem title="Add Product" link={Routes.Services.path} />
                <NavItem title="All Products" link={Routes.AllProducts.path} />
              </CollapsableNavItem>

              <CollapsableNavItem
                eventKey="diseases/"
                title="Diseases"
                icon={faDisease}
              >
                <NavItem title="Add Disease" link={Routes.AddDiseases.path} />
                <NavItem title="All Disease" link={Routes.AllDiseases.path} />
              </CollapsableNavItem>

              <NavItem
                title="About Us"
                link={Routes.About.path}
                icon={faVoicemail}
              />

              {/* <CollapsableNavItem
                eventKey="locate-us/"
                title="Location"
                icon={faLocationArrow}
              >
                <NavItem title="Add Location" link={Routes.AddLocation.path} />
                <NavItem title="All Location" link={Routes.AllLocation.path} />
                <NavItem title="Add City" link={Routes.AddCity.path} />
              </CollapsableNavItem> */}

              <CollapsableNavItem eventKey="blog/" title="Blog" icon={faBlog}>
                <NavItem title="Add Blog" link={Routes.AddBlog.path} />
                <NavItem title="All Blog" link={Routes.AllBlog.path} />
                <NavItem title="Add Category" link={Routes.Category.path} />
              </CollapsableNavItem>

              <NavItem
                title="Appointments"
                link={Routes.Appointment.path}
                icon={faCalendarAlt}
              />

              <NavItem
                title="Support"
                link={Routes.GetSupport.path}
                icon={faDatabase}
              />

              <NavItem
                title="Contact Us"
                link={Routes.ContactUs.path}
                icon={faChartPie}
              />

              <NavItem
                title="Newsletter"
                link={Routes.NewsLetter.path}
                icon={faInbox}
              />

              <NavItem
                title="Whats App"
                link={Routes.WhatsApp.path}
                icon={faInbox}
              />

              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem
                title="Logout"
                onClick={() => {
                  localStorage.setItem("token", "");
                  history.push("/");
                }}
                icon={faArrowLeft}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
