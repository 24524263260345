import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-hot-toast";

const CustomModal = ({
  type = "create",
  isOpen = false,
  activeItemId = "",
  isLoading,
  setIsLoading,
  setIsOpen = () => {},
}) => {
  const bannerRef = useRef();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      if (!activeItemId || type === "create") return;
      const res = await axios.get(`${baseUrl}/banner/${activeItemId}/`);
      createBannerFormik.setValues(res.data[0]);
      // console.log(res.data);
    })();
  }, [activeItemId]);

  const handleCreate = async (data) => {
    try {
      const res = await axios.post(`${baseUrl}/banner/`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (res?.status === 201) {
        setIsOpen(!isOpen);
        setIsLoading(!isLoading);
        toast.success("Successfully Created");
      }

      if (res?.response?.status === 401) {
        toast.error("Token expired!");
      }

      if (res?.response) {
        setIsOpen(!isOpen);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleUpdate = async (data) => {
    try {
      const res = await axios.patch(
        `${baseUrl}/banner/${activeItemId}/`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(res.data);

      if (res?.status === 200) {
        setIsOpen(!isOpen);
        setIsLoading(!isLoading);
        toast.success("Successfully Updated");
      }

      if (res?.response) {
        setIsOpen(!isOpen);
        toast.error("Something went wrong!");
      }

      if (res?.response?.status === 401) {
        toast.error("Token expired!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await axios.delete(`${baseUrl}/banner/${activeItemId}/`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (res?.status === 204) {
        setIsOpen(!isOpen);
        setIsLoading(!isLoading);
        toast.success("Successfully Deleted");
      }

      if (res?.response?.status === 401) {
        toast.error("Token expired!");
      }

      if (res?.response) {
        setIsOpen(!isOpen);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      setIsOpen(!isOpen);
      toast.error("Internal Server Error");
    }
  };

  const createBannerFormik = useFormik({
    initialValues: {
      image: "",
      title: "",
    },

    validationSchema: Yup.object({
      image: Yup.mixed()
        .required("This is required")
        .test("fileSize", "File size should be less than 2MB!", (e) => {
          if (typeof e === "string") return true;
          return e?.size <= 2 * (1000 * 1000);
        }),
      title: Yup.string()
        .required("This is required!")
        .min(3, "Atleast enter 3 letter!"),
    }),

    onSubmit: (values, action) => {
      console.log(values);
      if (type === "update")
        handleUpdate({ title: values.title, image: values.image });
      else handleCreate(values);
      action.resetForm();
      bannerRef.current.value = "";
    },
  });

  if (type === "delete") {
    return (
      <>
        <Modal
          show={isOpen}
          backdrop="static"
          centered
          onHide={() => setIsOpen(!isOpen)}
        >
          <Modal.Body>
            <div className="py-5">
              <h5 className="text-center">
                Are you sure want to delete this Banner?
              </h5>
              <div className="d-flex gap-3 align-items-center justify-content-center">
                <Button
                  variant="outline-secondary"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Cancle
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                  I'm Sure
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal show={isOpen} backdrop="static" onHide={() => setIsOpen(!isOpen)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "update" ? "Update Banner" : "Add Banner"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="wrapper my-3">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Choose Banner</Form.Label>
                <Form.Control
                  accept=".png,.jpeg,.jpg"
                  onChange={(e) =>
                    createBannerFormik.setFieldValue("image", e.target.files[0])
                  }
                  ref={bannerRef}
                  name="image"
                  onBlur={createBannerFormik.handleBlur}
                  isInvalid={
                    createBannerFormik.errors.image &&
                    createBannerFormik.touched.image
                      ? createBannerFormik.errors.image
                      : false
                  }
                  type="file"
                />
                {type === "update" ? (
                  <span>
                    {typeof createBannerFormik.values.image === "string"
                      ? createBannerFormik.values.image
                      : ""}
                  </span>
                ) : (
                  <></>
                )}
                <Form.Control.Feedback type="invalid">
                  {createBannerFormik.errors.image}
                </Form.Control.Feedback>
                <Form.Control.Feedback type=""></Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="wrapper my-3">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={createBannerFormik.values.title}
                  onBlur={createBannerFormik.handleBlur}
                  isInvalid={
                    createBannerFormik.errors.title &&
                    createBannerFormik.touched.title
                      ? createBannerFormik.errors.title
                      : false
                  }
                  onChange={createBannerFormik.handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {createBannerFormik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => setIsOpen(!isOpen)}>
            Close
          </Button>
          <Button variant="success" onClick={createBannerFormik.handleSubmit}>
            {type === "update" ? "Update" : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
