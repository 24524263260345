import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Modal from "./CustomModal";
import axios from "axios";
import { HiOutlinePlus } from "react-icons/hi";
import { MdDelete, MdEdit } from "react-icons/md";
import "./banner.scss";

const Banner = () => {
  const [isOpenUploadImage, setIsOpenUploadImage] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeId, setActiveId] = useState();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const imageBaseUrl = process.env.REACT_APP_API_BASE_IMAGE_URL;
  let access_token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      // setIsLoading(false);
      const res = await axios.get(`${baseUrl}/banner/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (res?.status === 200) {
        setData(res.data);
        // setIsLoading(false);
      }
    })();
  }, [baseUrl, isLoading]);

  return (
    <>
      <div className="screening_add_wrp">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <h3 className="mb-0">Banner Uploads</h3>
          <button
            onClick={() => setIsOpenUploadImage(!isOpenUploadImage)}
            className="add_btn btn btn-outline-dark px-3"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
          <Modal
            type="create"
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setIsOpen={setIsOpenUploadImage}
            isOpen={isOpenUploadImage}
          />
        </div>

        <div className="row">
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className="banner_card col-12 col-md-4 col-lg-3"
                >
                  <div className="list_dropdown">
                    <HiOutlinePlus />
                    <ul>
                      <li
                        onClick={() => {
                          setIsShowDeleteModal((prev) => !prev);
                          setActiveId(item.id);
                        }}
                      >
                        <MdDelete />
                      </li>
                      <li
                        onClick={() => {
                          setIsShowUpdateModal(true);
                          setActiveId(item.id);
                        }}
                      >
                        <MdEdit />
                      </li>
                    </ul>
                  </div>
                  <img src={`${imageBaseUrl}${item.image}`} alt={item.title} />
                </div>
              );
            })
          ) : (
            <h3 className="text-center">No Data Found</h3>
          )}
        </div>
      </div>
      <Modal
        setIsOpen={setIsShowDeleteModal}
        type="delete"
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isOpen={isShowDeleteModal}
        activeItemId={activeId}
      />
      <Modal
        setIsOpen={setIsShowUpdateModal}
        type="update"
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        activeItemId={activeId}
        isOpen={isShowUpdateModal}
      />
    </>
  );
};

export default Banner;
