import React, { useEffect, useRef, useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Card } from "@themesberg/react-bootstrap";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Modal, Button, Nav, Pagination } from "react-bootstrap";
import { BiDownload } from "react-icons/bi";
import ReactToPrint from "react-to-print";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const TransactionsTable = ({ filter }) => {
  const [transactions, setTransactions] = useState([]);
  const [totalEntries, setTotalEntriesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPagesCount] = useState(1);
  const [disablePrev, setDisablePrev] = useState(false);
  const [withIcons, setWithIcons] = useState(false);
  const [activeItem, setActiveItem] = useState(1);
  const [loader, setLoader] = useState(false);
  const items = [];

  for (let number = 1; number <= totalPages; number++) {
    const isItemActive = activeItem === number;

    const handlePaginationChange = () => {
      setActiveItem(number);
    };

    items.push(
      <Pagination.Item
        active={isItemActive}
        key={number}
        onClick={handlePaginationChange}
      >
        {number}
      </Pagination.Item>
    );
  }
  const onPrevItem = () => {
    const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
    setActiveItem(prevActiveItem);
    // getData(prevActiveItem, filter);
  };

  const onNextItem = (totalPages) => {
    const nextActiveItem =
      activeItem === totalPages ? activeItem : activeItem + 1;
    setActiveItem(nextActiveItem);
    // getData(nextActiveItem, filter);
  };

  const getData = async (pageNum = 1, filter) => {
    try {
      let access_token = localStorage.getItem("token");

      const res = await axios.get(
        `${baseUrl}/counter/${filter}?page=${pageNum}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (res.status === 200) {
        setTransactions(res.data);
        setTotalEntriesCount(res.data.count);
        setTotalPagesCount(res.data.total);
        setLoader(false);
      }
    } catch (error) {
      console.log("error catch getHubDropDownDataList block", error);
      setLoader(false);
      // toast.error(error.message, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
    }
  };

  useEffect(() => {
    getData(activeItem, filter);
  }, [activeItem, filter]);

  const TableRow = (props) => {
    const { date_and_time, location } = props.transactions;

    const getPageMargins = () => {
      return `@page { margin: 10px !important; }`;
    };

    return (
      <>
        <style>{getPageMargins()}</style>
        <tr>
          <td>
            <span className="fw-normal">{props.index + 1}</span>
          </td>
          <td>
            <span className="fw-normal">
              {`${new Date(date_and_time)
                .toISOString()
                .slice(0, 10)} ${new Date(date_and_time).toLocaleTimeString()}`}
            </span>
          </td>
          <td>
            <span className="fw-normal">
              {location ? location : "Not Found"}
            </span>
          </td>
        </tr>
      </>
    );
  };
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      {!loader ? (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">Sn.</th>
                  <th className="border-bottom">Date</th>
                  <th className="border-bottom">Location</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions?.current_counter?.length > 0 ? (
                  transactions?.current_counter?.map((t, index) => (
                    <TableRow
                      key={`transaction-${t?.id}`}
                      transactions={t}
                      index={index}
                    />
                  ))
                ) : (
                  <tr>
                    <td>
                      <span className="fw-normal no_item_found">
                        No item found
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <Nav>
                <Pagination size="md" className="mt-3">
                  <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
                    {withIcons ? (
                      <ChevronDoubleLeftIcon className="icon icon-xs" />
                    ) : (
                      "Previous"
                    )}
                  </Pagination.Prev>
                  {totalPages > 5 && activeItem > 3 ? (
                    <> {items.slice(activeItem - 3, activeItem + 2)}</>
                  ) : (
                    <>{items.slice(0, 5)}</>
                  )}
                  <Pagination.Next onClick={() => onNextItem(totalPages)}>
                    {withIcons ? (
                      <ChevronDoubleRightIcon className="icon icon-xs" />
                    ) : (
                      "Next"
                    )}
                  </Pagination.Next>
                </Pagination>
                <br />
              </Nav>
              <small className="fw-bold">
                Showing{" "}
                <b>
                  {10 * activeItem -
                    (10 - transactions?.current_counter?.length)}
                </b>{" "}
                out of <b>{totalEntries}</b> Entries
              </small>
            </Card.Footer>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default TransactionsTable;
