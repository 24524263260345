import React from "react";
import { useState, useRef, useEffect } from "react";
import { Button, ButtonGroup } from "@themesberg/react-bootstrap";
import { CSVLink } from "react-csv";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import TransactionsTable from "./Tables";

const headers = [
  { label: "Id", key: "id" },
  { label: "EMAIL", key: "email" },
];

export default () => {
  const [csvData, setCsvData] = useState([]);
  const csvDownloadRef = useRef(0);
  const [loader, setLoader] = useState(false);

  const fetchDataToDownload = async () => {
    try {
      let access_token = localStorage.getItem("token");
      setLoader(true);
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      };
      const response = await fetch(
        "https://api.atulyahearing.com/api/newsletter/",
        config
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        setCsvData(data.all_data);
        setTimeout(() => {
          csvDownloadRef.current.link.click();
        }, 500);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    console.log("csvDAta", csvData);
  }, [csvData]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>News-Letter</h3>
        </div>
        <div style={{ textAlign: "center" }}>
          <PropagateLoader
            color="#4A5073"
            loading={loader}
            size={15}
            aria-label="Loading Spinner"
          />
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <CSVLink
            headers={headers}
            data={csvData}
            filename="news-letter.csv"
            className="hidden"
            ref={csvDownloadRef}
            target="_blank"
          />
          <ButtonGroup>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={fetchDataToDownload}
            >
              Export Csv
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <TransactionsTable />
    </>
  );
};
