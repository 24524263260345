import React from "react";
import { Col, Row} from "@themesberg/react-bootstrap";
import { GeneralInfoForm } from "./Forms";


export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <h3>Add Product</h3>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <GeneralInfoForm />
        </Col>
      </Row>
    </>
  );
};
