import React from "react";
import { Row } from "@themesberg/react-bootstrap";
import { useState, useEffect, useRef } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import group1 from "../../assets/group1.jpg";
import { BsWhatsapp } from "react-icons/bs";
import axios from "axios";

export default () => {
  const [loader, setLoader] = useState(true);
  const [counter, setCounter] = useState(0);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("token");
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseUrl}/counter/`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setCounter(res.data.count);
      console.log(res.data);
    })();

    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <h3>Admin Dashboard</h3>
        <div className="d-flex justify-content-center align-items-center gap-4 bg-white p-2 rounded-5 shadow-lg">
          <BsWhatsapp className="text-success" /> {counter}
        </div>
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      <Row className="justify-content-md-center">
        <img
          src={group1}
          style={{ borderRadius: "50px" }}
          alt="dashboard-image"
        ></img>
      </Row>
      <br />
      <br />
    </>
  );
};
