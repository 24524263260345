import React, { useState, useRef, useMemo } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Card, Form, Button } from "@themesberg/react-bootstrap";
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";

const AddDiseases = (props) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const imageBaseUrl = process.env.REACT_APP_API_BASE_IMAGE_URL;
  const history = useHistory();
  const [bannerData, setBannerData] = useState(props?.data?.content);
  const [bannerData1, setBannerData1] = useState(props?.data?.content_ext);
  const [placeholder, setPlaceholder] = useState("Start typings...");
  const attachmentInputRef = useRef();
  const [title, setTitle] = useState(props?.data?.title);
  const [slug, setSlug] = useState(props?.data?.slug);
  const [attactment, setAttachment] = useState({
    name: "",
    value: "",
  });
  const [mainHeadin, setMainHeadin] = useState(props?.data?.main_heading);
  const [metaTitle, setMetaTitle] = useState(props?.data?.meta_title);
  const [metaDescription, setMetaDescription] = useState(
    props?.data?.meta_description
  );
  let youKnowContent = "";
  if (props.keyword === "update") {
    youKnowContent = `<p>${props?.data?.you_know_detail}</p>`;
  }
  const [selectedFile, setSelectedFile] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    title: "",
    attachment: "",
    mainHeadin: "",
    bannerDataErr: "",
    causesHead: "",
    causesDetail: "",
    exposureHead: "",
    exposureDetail: "",
    reqAppointment: "",
    reqDetail: "",
    soundTherapyHead: "",
    soundTherapyDetailErr: "",
    youKnowHead: "",
    youKnowDetailErr: "",
    metaTitle: "",
    metaDescription: "",
  });

  const editor = useRef(null);
  const editor1 = useRef(null);

  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: placeholder || "Start typings...",
    };
  }, [placeholder]);

  const bannerDataInputClickHandler = (event) => {
    let parser = new DOMParser();
    const doc = parser.parseFromString(event, "text/html");
    let editorWrp = document.querySelector("#about-data-editor .jodit-wysiwyg");
    const childN = editorWrp.childNodes;
    let emptyParaLength = 0;
    if (childN.length === 1) {
      const findWrp = doc.getElementsByTagName("p")[0];
      if (findWrp.innerHTML !== "<br>") {
        setBannerData(event);
        setError({ ...error, bannerDataErr: "" });
      } else {
        setError({ ...error, bannerDataErr: "Please enter about data" });
      }
    } else {
      for (var i = 0; i < childN.length; i++) {
        if (childN[i].innerHTML !== "<br>") {
          emptyParaLength += 1;
        }
      }
      if (emptyParaLength >= 1) {
        setBannerData(event);
        setError({ ...error, bannerDataErr: "" });
      } else {
        setError({ ...error, bannerDataErr: "Please enter about data" });
      }
    }
  };

  const bannerDataInputClickHandler1 = (event) => {
    let parser = new DOMParser();
    const doc = parser.parseFromString(event, "text/html");
    let editorWrp = document.querySelector("#about-data-editor .jodit-wysiwyg");
    const childN = editorWrp.childNodes;
    let emptyParaLength = 0;
    if (childN.length === 1) {
      const findWrp = doc.getElementsByTagName("p")[0];
      if (findWrp.innerHTML !== "<br>") {
        setBannerData1(event);
        setError({ ...error, bannerDataErr: "" });
      } else {
        setError({ ...error, bannerDataErr: "Please enter about data" });
      }
    } else {
      for (var i = 0; i < childN.length; i++) {
        if (childN[i].innerHTML !== "<br>") {
          emptyParaLength += 1;
        }
      }
      if (emptyParaLength >= 1) {
        setBannerData1(event);
        setError({ ...error, bannerDataErr: "" });
      } else {
        setError({ ...error, bannerDataErr: "Please enter about data" });
      }
    }
  };

  const inputClickHandler = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "attachment":
        if (value === "" || value == null || !value) {
          if (!attactment) {
            setError({ ...error, attachment: "can not be empty" });
          }
        } else {
          setError({ ...error, attachment: "" });
        }
        if (value) {
          setAttachment({
            name: event.target.files[0].name,
            value: event.target.files[0],
          });
          setSelectedFile(true);
        }
        break;
      case "title":
        if (value === "") {
          setError({ ...error, title: "Title can not be empty" });
        } else {
          setError({ ...error, title: "" });
        }
        setTitle(value);
        setSlug(value.split(" ").join("-"));
        break;

      case "slug":
        if (value === "") {
          setError({ ...error, slug: "Slug can not be empty" });
        } else {
          setError({ ...error, slug: "" });
        }
        setSlug(value);
        break;

      case "mainHeadin":
        if (value === "") {
          setError({ ...error, mainHeadin: "Main Heading can not be empty" });
        } else {
          setError({ ...error, mainHeadin: "" });
        }
        setMainHeadin(value);
        break;
      case "metaTitle":
        setMetaTitle(value);
        break;
      case "metaDescription":
        setMetaDescription(value);
        break;
      default:
        break;
    }
  };

  const handleSubmitError = async (e) => {
    e.preventDefault();
    let errorMsg1 = "";
    let errorMsg2 = "";
    let errorMsg3 = "";

    if (!props?.data?.banner_img) {
      if (
        attactment.value == "" ||
        attactment.value == null ||
        !attactment.value
      ) {
        errorMsg1 = "Image can not be empty";
      } else {
        errorMsg1 = "";
      }
    }

    if (title === "" || title === undefined) {
      errorMsg2 = "Title can not be empty";
    } else {
      errorMsg2 = "";
    }

    if (
      bannerData === "" ||
      error.bannerDataErr !== "" ||
      bannerData === undefined
    ) {
      errorMsg3 = "Please enter about data";
    } else {
      errorMsg3 = "";
    }

    setError({
      ...error,
      title: errorMsg2,
      attachment: errorMsg1,
      bannerDataErr: errorMsg3,
    });
    if (errorMsg1 === "" && errorMsg2 === "" && errorMsg3 === "") {
      onSubmitHandler();
    }
  };

  const onSubmitHandler = async () => {
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      let formData = new FormData();

      if (props?.keyword === "update") {
        formData.append("id", props?.data?.id);

        if (props?.data?.title !== title) {
          formData.append("title", title);
        }
        if (props?.data?.slug !== slug) {
          formData.append("slug", slug);
        }
        if (attactment.value && attactment.name) {
          formData.append("banner_img", attactment?.value, attactment?.name);
        }
        if (props?.data?.main_heading !== mainHeadin) {
          formData.append("main_heading", mainHeadin);
        }
        if (props?.data?.meta_title !== metaTitle) {
          formData.append("meta_title", metaTitle);
        }
        if (props?.data?.meta_description !== metaDescription) {
          formData.append("meta_description", metaDescription);
        }
        if (props?.data?.content !== bannerData) {
          formData.append("content", bannerData);
        }
        if (props?.data?.content_ext !== bannerData1) {
          formData.append("content_ext", bannerData1);
        }
      } else {
        formData.append("title", title);
        formData.append("slug", slug);
        formData.append("banner_img", attactment?.value, attactment?.name);
        formData.append("main_heading", mainHeadin);
        formData.append("meta_title", metaTitle);
        formData.append("meta_description", metaDescription);
        formData.append("content", bannerData);
        formData.append("content_ext", bannerData1);
      }

      let response = await axios({
        method: `${props?.keyword === "update" ? "patch" : "post"}`,
        url: `${
          props?.keyword === "update"
            ? `${baseUrl}/disease/${props?.data?.slug}/`
            : `${baseUrl}/add-disease/`
        }`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;boundary=`---MyBoundaryIs123`",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response.data) {
        setLoader(false);
        history.push("/all-diseases");

        toast.success("Added Successfully!", {
          position: "top-right",
          autaccess_tokenoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        if (props?.keyword !== "update") {
          setSelectedFile(false);
          setTitle("");
          setSlug("");
          setAttachment({
            name: "",
            value: "",
          });
          setMainHeadin("");
          setBannerData("<p></p>");
          setBannerData1("<p></p>");
        } else {
          props.updatedata();
          props.hideModal();
        }
      } else {
        setLoader(false);
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <div className="add_diseases_wrp">
        <h3 className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">{`${
          props?.keyword === "update" ? "Update Disease" : "Add Disease"
        }`}</h3>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <Form method="post">
              <Form.Group className="mb-3">
                <Form.Label>Disease Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  required
                  value={title}
                  onChange={(e) => {
                    inputClickHandler(e);
                  }}
                />
                <p style={{ color: "red" }}>{error.title}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Disease Slug</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter slug"
                  name="slug"
                  required
                  onBlur={() => {
                    setSlug(slug.split(" ").join("-"));
                  }}
                  isInvalid={error.slug}
                  isValid={slug}
                  value={slug}
                  onChange={(e) => {
                    inputClickHandler(e);
                  }}
                />
                {error.slug && (
                  <Form.Control.Feedback type="isInvalid">
                    Please enter a valid slug.
                  </Form.Control.Feedback>
                )}
                <Form.Control.Feedback type="valid">
                  Looks good!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Main Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  name="mainHeadin"
                  required
                  value={mainHeadin}
                  onChange={(e) => {
                    inputClickHandler(e);
                  }}
                />
                <p style={{ color: "red" }}>{error.mainHeadin}</p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Meta Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Meta title"
                  name="metaTitle"
                  value={metaTitle}
                  onChange={(e) => {
                    inputClickHandler(e);
                  }}
                />
                {/* <p style={{ color: "red" }}>{error.mainHeadin}</p> */}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Meta Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Meta Description"
                  name="metaDescription"
                  value={metaDescription}
                  onChange={(e) => {
                    inputClickHandler(e);
                  }}
                />
                {/* <p style={{ color: "red" }}>{error.metaTitle}</p> */}
              </Form.Group>
              <Form.Group>
                <Form.Label>Choose Product Image</Form.Label>
                <Form.Control
                  type="file"
                  name="attachment"
                  accept="image/png, image/jpeg"
                  required
                  ref={attachmentInputRef}
                  onChange={(e) => {
                    inputClickHandler(e);
                  }}
                />
                <p style={{ color: "red" }}>{error.attachment}</p>
              </Form.Group>
              {selectedFile ? (
                <img
                  className="same"
                  style={{
                    maxWidth: "60%",
                    border: "2px solid #ede7e7",
                    borderRadius: "10px",
                  }}
                  src={window.URL.createObjectURL(attactment?.value)}
                  alt="product"
                />
              ) : (
                <img src={`${imageBaseUrl}${props?.data?.banner_img}`} alt="" />
              )}
              <Form.Group id="about-data-editor">
                <JoditEditor
                  ref={editor1}
                  config={config}
                  tabIndex={1}
                  value={props?.data?.content}
                  onBlur={(e) => setBannerData(e)}
                  onChange={(e) => {
                    bannerDataInputClickHandler(e);
                  }}
                />
                <p style={{ color: "red" }}>{error.bannerDataErr}</p>
              </Form.Group>

              <Form.Group id="about-data-editor">
                <JoditEditor
                  ref={editor}
                  config={config}
                  tabIndex={1}
                  value={props?.data?.content_ext}
                  onBlur={(e) => setBannerData1(e)}
                  onChange={(e) => {
                    bannerDataInputClickHandler1(e);
                  }}
                />
                <p style={{ color: "red" }}>{error.bannerDataErr}</p>
              </Form.Group>

              <Button
                variant="primary"
                type="button"
                onClick={handleSubmitError}
              >
                {loader
                  ? "Loading.."
                  : props?.keyword === "update"
                  ? "Update"
                  : "Submit"}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default AddDiseases;
