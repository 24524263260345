import React from 'react'
import Modal from "react-bootstrap/Modal";
import AddBlog from './AddBlog'
import Button from 'react-bootstrap/Button';

const UpdateBlog = (props) => {
  return (
  <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className='diseases_edit_modal'
  >
    <Modal.Header closeButton>
      </Modal.Header>
        <Modal.Body>
          <AddBlog hideModal={props.onHide} updatedata={props.getblogdata} data={props?.selectedBlog} keyword="update" />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateBlog