import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// core styles
import "./scss/volt.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// vendor styles
import "react-datetime/css/react-datetime.css";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { AuthContextProvider } from "./store/auth-context";

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <ScrollToTop />
      <HomePage />
      <ToastContainer />
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
