import React, { useRef, useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Card } from "@themesberg/react-bootstrap";
import { Table } from "react-bootstrap";
import { Nav, Pagination } from "react-bootstrap";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";

const TableRow = (props) => {
  const { score, phone_number, date_and_time } = props.transaction;

  return (
    <>
      <tr>
        <td>
          <span className="fw-normal">{props.index + 1}</span>
        </td>
        <td>
          <span className="fw-normal">{phone_number}</span>
        </td>
        <td>
          <span className="fw-normal">{score}</span>
        </td>
        <td>
          <span className="fw-normal">{date_and_time}</span>
        </td>
      </tr>
    </>
  );
};

const TransactionsTable = ({ transactions, loader }) => {
  // const [modalData, setModalData] = useState({});
  const [totalEntries, setTotalEntriesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPagesCount] = useState(1);
  const [disablePrev, setDisablePrev] = useState(false);
  const [withIcons, setWithIcons] = useState(false);
  const [activeItem, setActiveItem] = useState(1);
  const items = [];
  const printRef = useRef();
  for (let number = 1; number <= totalPages; number++) {
    const isItemActive = activeItem === number;

    const handlePaginationChange = () => {
      setActiveItem(number);
    };

    items.push(
      <Pagination.Item
        active={isItemActive}
        key={number}
        onClick={handlePaginationChange}
      >
        {number}
      </Pagination.Item>
    );
  }
  const onPrevItem = () => {
    const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
    setActiveItem(prevActiveItem);
  };

  const onNextItem = (totalPages) => {
    const nextActiveItem =
      activeItem === totalPages ? activeItem : activeItem + 1;
    setActiveItem(nextActiveItem);
  };
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      {!loader ? (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">Sn.</th>
                  <th className="border-bottom">Mobile</th>
                  <th className="border-bottom">Score</th>
                  <th className="border-bottom">Date</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions?.all_data?.length > 0 ? (
                  transactions?.all_data?.map((t, index) => (
                    <TableRow
                      key={`transaction-${t?.id}`}
                      transaction={t}
                      index={index}
                    />
                  ))
                ) : (
                  <tr>
                    <td>
                      <span className="fw-normal no_item_found">
                        No item found
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <Nav>
                <Pagination size="md" className="mt-3">
                  <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
                    {withIcons ? (
                      <ChevronDoubleLeftIcon className="icon icon-xs" />
                    ) : (
                      "Previous"
                    )}
                  </Pagination.Prev>
                  {totalPages > 5 && activeItem > 3 ? (
                    <> {items.slice(activeItem - 3, activeItem + 2)}</>
                  ) : (
                    <>{items.slice(0, 5)}</>
                  )}
                  <Pagination.Next onClick={() => onNextItem(totalPages)}>
                    {withIcons ? (
                      <ChevronDoubleRightIcon className="icon icon-xs" />
                    ) : (
                      "Next"
                    )}
                  </Pagination.Next>
                </Pagination>
                <br />
              </Nav>
              <small className="fw-bold">
                Showing <b>{10 * activeItem - (10 - transactions.length)}</b>{" "}
                out of <b>{totalEntries}</b> Entries
              </small>
            </Card.Footer>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default TransactionsTable;
