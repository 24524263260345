import React, { useState, useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { ButtonGroup, Button } from "react-bootstrap";
import TransactionsTable from "./Tables";
import axios from "axios";

const UserScreening = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const csvDownloadRef = useRef();

  const headers = [
    { label: "Mobile", key: "phone_number" },
    { label: "Score", key: "score" },
    { label: "Data&Time", key: "date_and_time" },
  ];

  useEffect(() => {
    (async () => {
      let access_token = localStorage.getItem("token");
      setLoader(true);
      const res = await axios.get(`${baseUrl}/screening/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (res?.status === 200) {
        console.log(res.data);
        setLoader(false);
        setData(res.data);
      }

      if (res?.code === "ERR_BAD_REQUEST") {
      }
    })();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>User's Screening</h3>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <>
            {console.log(data.all_data, "data")}
            {data.all_data && (
              <>
                <CSVLink
                  headers={headers}
                  data={data.all_data}
                  filename="user_screening.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <ButtonGroup>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => csvDownloadRef.current.link.click()}
                  >
                    Export Csv
                  </Button>
                </ButtonGroup>
              </>
            )}
          </>
        </div>
      </div>
      <TransactionsTable loader={loader} transactions={data} />
    </>
  );
};

export default UserScreening;
