
import moment from "moment-timezone";

export default [
    {
        "invoiceNumber": 1,
        "status": "Want to take a 2-min online hearing health checkup?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        // "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "issueDate": "tanuj.g@webllisto.com",
        "dueDate": "+91 9098839391"
    },
    {
        "invoiceNumber": 2,
        "status": "Have a hearing aid test report, need consultation?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 3,
        "status": "Have a doubt on your hearing loss?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 12,
        "status": "Want to take a 2-min online hearing health checkup?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        // "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "issueDate": "tanuj.g@webllisto.com",
        "dueDate": "+91 9098839391"
    },
    {
        "invoiceNumber": 21,
        "status": "Have a hearing aid test report, need consultation?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 33,
        "status": "Have a doubt on your hearing loss?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 12,
        "status": "Want to take a 2-min online hearing health checkup?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        // "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "issueDate": "tanuj.g@webllisto.com",
        "dueDate": "+91 9098839391"
    },
    {
        "invoiceNumber": 62,
        "status": "Have a hearing aid test report, need consultation?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 73,
        "status": "Have a doubt on your hearing loss?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 81,
        "status": "Want to take a 2-min online hearing health checkup?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        // "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "issueDate": "tanuj.g@webllisto.com",
        "dueDate": "+91 9098839391"
    },
    {
        "invoiceNumber": 52,
        "status": "Have a hearing aid test report, need consultation?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 23,
        "status": "Have a doubt on your hearing loss?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 91,
        "status": "Want to take a 2-min online hearing health checkup?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        // "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "issueDate": "tanuj.g@webllisto.com",
        "dueDate": "+91 9098839391"
    },
    {
        "invoiceNumber": 12,
        "status": "Have a hearing aid test report, need consultation?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 343,
        "status": "Have a doubt on your hearing loss?",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 300497,
        "status": "Paid",
        "subscription": "Flexible Subscription Plan",
        "price": "233,42",
        "issueDate": moment().subtract(3, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(3, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 300496,
        "status": "Due",
        "subscription": "Gold Subscription Plan",
        "price": "533,42",
        "issueDate": moment().subtract(1, "day").subtract(1, "month").format("DD MMM YYYY"),
        "dueDate": moment().subtract(1, "day").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 300495,
        "status": "Due",
        "subscription": "Gold Subscription Plan",
        "price": "533,42",
        "issueDate": moment().subtract(3, "days").subtract(1, "month").format("DD MMM YYYY"),
        "dueDate": moment().subtract(3, "days").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 300494,
        "status": "Due",
        "subscription": "Flexible Subscription Plan",
        "price": "233,42",
        "issueDate": moment().subtract(4, "days").subtract(1, "month").format("DD MMM YYYY"),
        "dueDate": moment().subtract(4, "days").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 300493,
        "status": "Canceled",
        "subscription": "Gold Subscription Plan",
        "price": "533,42",
        "issueDate": moment().subtract(20, "days").subtract(1, "month").format("DD MMM YYYY"),
        "dueDate": moment().subtract(20, "days").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 300492,
        "status": "Canceled",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(2, "months").format("DD MMM YYYY"),
        "dueDate": moment().subtract(3, "months").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 300491,
        "status": "Paid",
        "subscription": "Platinum Subscription Plan",
        "price": "799,00",
        "issueDate": moment().subtract(6, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(6, "days").add(1, "month").format("DD MMM YYYY")
    }
]