import React from "react";
import { useEffect, useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import EditLocation from "./editLocation";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import "./locateus.scss";
import { Link } from "react-router-dom";

const AllLocation = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const imageBaseUrl = process.env.REACT_APP_API_BASE_IMAGE_URL;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeItemId, setActiveItemId] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();

  const getblogdata = async () => {
    try {
      let response = await axios.get(`${imageBaseUrl}/hospital/locate-us/`);
      if (response.statusText) {
        setData(response.data);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const deleteProductHandler = async (itemId) => {
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      let response = await axios({
        method: "delete",
        url: `${imageBaseUrl}/hospital/locate-us/${itemId}/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response) {
        setLoader(false);
        setIsOpenModal(false);
        toast.success("Blog Deleted", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getblogdata();
      } else {
        setLoader(false);
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const setModalPlusIdPassHandler = (item) => {
    setModalShow(true);
    setSelectedBlog(item);
  };

  useEffect(() => {
    getblogdata();
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      <div className="services_pg_wrp">
        <section className="Diagnostic_tests_wrp">
          <div className="container">
            <div className="Diagnostic_tests_inn_wrp">
              <div className="d-flex justify-content-between align-items-center">
                <h3>All Location</h3>
                <Link className="btn btn-primary" to="/locate-us/add">
                  Add Location
                </Link>
              </div>
              <div className="row">
                {data &&
                  data?.map((item, i) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 services_cards"
                        key={i}
                      >
                        <div className="tests_cards all_location_card">
                          <div className="product_image_wrp card_main_wrp"></div>
                          <div>
                            <h3>Branches:{item?.branches}</h3>
                            <div className="desc d-flex align-items-baseline">
                              <h6>Address:</h6> {item?.address}
                            </div>
                            <div className="desc d-flex align-items-baseline">
                              <h6>City:</h6> {item?.city_name}
                            </div>
                            <div className="desc d-flex align-items-baseline">
                              <h6>Contect:</h6> {item?.contact}
                            </div>
                            <div className="desc d-flex align-items-baseline">
                              <h6>Timings</h6> {item?.timings}
                            </div>
                          </div>
                          <Dropdown className="list_options">
                            <Dropdown.Toggle id="list_options">
                              &#8230;
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setModalPlusIdPassHandler(item)}
                              >
                                Update
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setIsOpenModal(true);
                                  setActiveItemId(item.id);
                                }}
                              >
                                Remove
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      </div>
      <EditLocation
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedBlog={selectedBlog}
        getblogdata={getblogdata}
      />
      <Modal
        onHide={() => setIsOpenModal(!isOpenModal)}
        show={isOpenModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h4>Are You Want to Delete?</h4>
            <button
              onClick={() => deleteProductHandler(activeItemId)}
              className="btn btn-primary"
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllLocation;
