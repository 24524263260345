import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import Signin from "./signIn/Signin";
import ContactUs from "./contact-us/ContactUs";
import Appointment from "./appointment/Appointment";
import NewsLetter from "./newsletter/NewsLetter";
import About from "./about/About";
import Services from "./services/Services";
import AllProducts from "./services/Services";
import ListAllProducts from "./services/ListAllProducts";
import NotFoundPage from "./examples/NotFound";
import Transactions from "./Transactions";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
import DashboardOverview from "./dashboard/DashboardOverview";
import AddDiseases from "./diseases/add-diseases";
import AllDiseases from "./diseases/all-diseases";
import Partners from "./partners/partners";
import AddBlog from "./blog/AddBlog";
import AllBlog from "./blog/AllBlog";
import Category from "./blog/Category";
import UserScreening from "../components/UserScreening";
import ScreeningAdd from "../components/ScreeningAdd";
import GetSupport from "./get-support";
import AddLocation from "./locate-us/addLocation";
import AllLocation from "./locate-us/allLocation";
import AddCity from "./locate-us/addCity";
import Banner from "../components/Banner";
import WhatsAppUserTable from "./WhatsAppUserTable";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};
const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content">
            <Navbar />
            <Component {...props} />
            {/* <Footer toggleSettings={toggleSettings} showSettings={showSettings} /> */}
          </main>
        </>
      )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Presentation.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithSidebar
      exact
      path={Routes.DashboardOverview.path}
      component={DashboardOverview}
    />
    <RouteWithSidebar
      exact
      path={Routes.ContactUs.path}
      component={ContactUs}
    />
    <RouteWithSidebar
      exact
      path={Routes.Appointment.path}
      component={Appointment}
    />
    <RouteWithSidebar
      exact
      path={Routes.NewsLetter.path}
      component={NewsLetter}
    />
    <RouteWithSidebar
      exact
      path={Routes.WhatsApp.path}
      component={WhatsAppUserTable}
    />
    <RouteWithSidebar exact path={Routes.Partners.path} component={Partners} />
    <RouteWithSidebar
      exact
      path={Routes.GetSupport.path}
      component={GetSupport}
    />
    <RouteWithSidebar exact path={Routes.About.path} component={About} />
    <RouteWithSidebar exact path={Routes.Services.path} component={Services} />

    {/* <RouteWithSidebar
      exact
      path={Routes.AddLocation.path}
      component={AddLocation}
    />
    <RouteWithSidebar
      exact
      path={Routes.AllLocation.path}
      component={AllLocation}
    /> */}
    <RouteWithSidebar exact path={Routes.AddCity.path} component={AddCity} />

    <RouteWithSidebar
      exact
      path={Routes.AllProducts.path}
      component={ListAllProducts}
    />
    <RouteWithSidebar
      exact
      path={Routes.Transactions.path}
      component={Transactions}
    />
    <RouteWithSidebar
      exact
      path={Routes.AddDiseases.path}
      component={AddDiseases}
    />
    <RouteWithSidebar
      exact
      path={Routes.AllDiseases.path}
      component={AllDiseases}
    />
    <RouteWithSidebar exact path={Routes.AddBlog.path} component={AddBlog} />
    <RouteWithSidebar exact path={Routes.AllBlog.path} component={AllBlog} />
    <RouteWithSidebar exact path={Routes.Category.path} component={Category} />
    <RouteWithSidebar
      exact
      path={Routes.UserScreening.path}
      component={UserScreening}
    />
    <RouteWithSidebar
      exact
      path={Routes.ScreeningAdd.path}
      component={ScreeningAdd}
    />

    <RouteWithSidebar exact path={Routes.Banner.path} component={Banner} />
    {/* <Redirect exact path="*" to="/" /> */}
  </Switch>
);
