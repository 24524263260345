import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import RemoveProductModal from "./remove-modal";

function ListAllProducts() {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [modalShow, setModalShow] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [selectedproduct, setSelectedProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [curr, setCurr] = useState();

  const getprodcutdata = async () => {
    try {
      let response = await axios.get(`${baseUrl}/product/`);
      if (response.statusText) {
        setData(response.data);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const deleteProductHandler = async (itemId) => {
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      let response = await axios({
        method: "delete",
        url: `https://api.atulyahearing.com/api/product/${itemId}/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response) {
        setLoader(false);
        toast.success("Product Deleted", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getprodcutdata();
      } else {
        setLoader(false);
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const setModalPlusIdPassHandler = (item) => {
    setModalShow(true);
    setSelectedProduct(item);
  };

  useEffect(() => {
    getprodcutdata();
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      <div className="services_pg_wrp">
        <section className="Diagnostic_tests_wrp">
          <div className="container">
            <div className="Diagnostic_tests_inn_wrp">
              <div>
                <h3>All Hearing Products</h3>
              </div>
              <div className="row">
                {data &&
                  data.map((item, i) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 services_cards"
                        key={i}
                      >
                        <div className="tests_cards">
                          <div className="product_image_wrp">
                            <img src={item?.image_url} alt="" />
                          </div>
                          <div>
                            <h3>{item?.title}</h3>
                            <div className="desc">{item?.description}</div>
                          </div>
                          <Dropdown className="list_options">
                            <Dropdown.Toggle id="list_options">
                              &#8230;
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setModalPlusIdPassHandler(item)}
                              >
                                Update
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setIsShowDeleteModal(true);
                                  setCurr(item.slug);
                                }}
                              >
                                Remove
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      </div>

      <RemoveProductModal
        data={curr}
        show={isShowDeleteModal}
        updatedata={getprodcutdata}
        onHide={() => setIsShowDeleteModal(false)}
      />

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedproduct={selectedproduct}
        getprodcutdata={getprodcutdata}
      />
    </>
  );
}

export default ListAllProducts;
