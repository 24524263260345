import React, { useState, useRef, useMemo, useEffect } from "react";
import { Row, Card, Form } from "@themesberg/react-bootstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { Button } from "react-bootstrap";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#888888",
};

export const GeneralInfoForm = () => {
  const [previousRecords, setPreviousRecords] = useState([]);
  const [bannerData, setBannerData] = useState("");
  const [middleField, setMiddleField] = useState("");
  const [chooseUs, setChooseUs] = useState("");
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const [placeholder, setPlaceholder] = useState("Start");
  const editor = useRef(null);

  const [error, setError] = useState({
    description: "",
  });

  const config1 = useMemo(() => {
    return {
      readonly: false,
      placeholder: placeholder || "Start typings...",
    };
  }, [placeholder]);
  const config2 = useMemo(() => {
    return {
      readonly: false,
      placeholder: placeholder || "Start typings...",
    };
  }, [placeholder]);
  const config3 = useMemo(() => {
    return {
      readonly: false,
      placeholder: placeholder || "Start typings...",
    };
  }, [placeholder]);

  // const inputClickHandler = (event) => {
  // const { name, value } = event.target;
  // console.log("about us pageeee", name, "valueeeee", value);
  // switch (name) {
  //   case "description":
  //     if (value === "") {
  //       setError({ ...error, description: "Description can not be empty" });
  //     } else {
  //       setError({ ...error, description: "" });
  //     }
  //     setDescription(value);
  //     break;
  //   default:
  //     break;
  // }
  // console.log("about us pageeee", event);
  // };

  const bannerDataInputClickHandler = (event) => {
    if (event.length > 0) {
      setBannerData(event);
      console.log("bannerDataInputClickHandler pageeee", event);
    } else {
      setBannerData("");
    }
  };
  const enterMiddleFieldInputClickHandler = (event) => {
    if (event.length > 0) {
      setMiddleField(event);
      console.log("enterMiddleFieldInputClickHandler pageeee", event);
    } else {
      setMiddleField("");
    }
  };
  const chooseUsInputClickHandler = (event) => {
    if (event.length > 0) {
      setChooseUs(event);
      console.log("chooseUsInputClickHandler pageeee", event);
    } else {
      setChooseUs("");
    }
  };

  const onSubmitHandler = async () => {
    setLoader(true);
    let aboutUsData = {
      bannerdata: bannerData,
      middle_field_data: middleField,
      choose_us: chooseUs,
    };

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(aboutUsData),
    };

    try {
      let response = await fetch("https://api.atulyahearing.com/api/about/", config);
      let data = await response.json();

      console.log("appointment page success output", data);

      if (!response.ok) {
        throw new Error(response.error);
      } else {
        toast.success("Your post updated!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setLoader(false);
    } catch (error) {
      setError(error);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoader(false);
    }
  };

  const getPreviousAboutUsPageData = async () => {
    setLoader(true);
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch("https://api.atulyahearing.com/api/about/", config);
      const data = await response.json();

      if (!response.ok) {
        throw new Error("Something went wrong!");
      } else {
        setPreviousRecords(data);
        setBannerData(data?.bannerdata);
        setMiddleField(data?.middle_field_data);
        setChooseUs(data?.choose_us);
      }

      setLoader(false);
    } catch (error) {
      toast.error("Problem in fetching previous records...", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    getPreviousAboutUsPageData();
    return () => {
      setState({});
    };
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      {!loader ? (
        <>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <Form>
                <Row className="align-items-center">
                  <Form.Group>
                    <Form.Label>Enter Banner data...</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={bannerData}
                      config={config1}
                      tabIndex={1}
                      onBlur={(bannerData) => setBannerData(bannerData)}
                      onChange={(e) => {
                        bannerDataInputClickHandler(e);
                      }}
                    />
                  </Form.Group>
                </Row>
                <p style={{ color: "red" }}>{error.description}</p>
              </Form>
            </Card.Body>
          </Card>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <Form>
                <Row className="align-items-center">
                  <Form.Group>
                    <Form.Label>Enter Middle Field data</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={middleField}
                      config={config2}
                      tabIndex={2} // tabIndex of textarea
                      onBlur={(middleField) => setMiddleField(middleField)}
                      onChange={(e) => {
                        enterMiddleFieldInputClickHandler(e);
                      }}
                    />
                  </Form.Group>
                </Row>
                <p style={{ color: "red" }}>{error.description}</p>
              </Form>
            </Card.Body>
          </Card>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <Form>
                <Row className="align-items-center">
                  <Form.Group>
                    <Form.Label>Enter why choose_us data...</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={chooseUs}
                      config={config3}
                      tabIndex={3}
                      onBlur={(chooseUs) => setChooseUs(chooseUs)}
                      onChange={(e) => {
                        chooseUsInputClickHandler(e);
                      }}
                    />
                  </Form.Group>
                </Row>
                <p style={{ color: "red" }}>{error.description}</p>
              </Form>
            </Card.Body>
            <div className="mt-3">
              <Button
                variant="primary"
                className="animate-right-3 mb-2 me-2"
                size="lg"
                type="submit"
                onClick={onSubmitHandler}
              >
                Update
              </Button>
            </div>
          </Card>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
