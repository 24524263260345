import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AddDiseases from './add-diseases';

const DiseasesEditModal = (props) => {
  return (
    <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='diseases_edit_modal'
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <AddDiseases updatedata={props.updatedata} hideModal={props.onHide} data={props?.data} keyword="update" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default DiseasesEditModal;