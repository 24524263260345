import React from "react";
import { useEffect, useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import UpdateBlog from "./UpdateBlog";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import "./blog.scss";
import { CiFilter } from "react-icons/ci";
import RemoveBlogModel from "./RemoveBlogModel";
import { Form } from "@themesberg/react-bootstrap";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css";

const AllBlog = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const imageBaseUrl = process.env.REACT_APP_API_BASE_IMAGE_URL;
  const [currItem, setCurrItem] = useState([]);
  const [removeDiseaseModal, setRemoveDiseaseModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [category, setCategory] = useState([]);
  let access_token = localStorage.getItem("token");
  const setModalPlusIdPassHandler = (item) => {
    setModalShow(true);
    setSelectedBlog(item);
  };
  const removeDataHandler = (item) => {
    setRemoveDiseaseModal(true);
    setCurrItem(item);
  };

  const getblogdata = async (category, sdate, ldate) => {
    console.log(category, sdate, ldate);
    let url = `${imageBaseUrl}/blog/search-blog/?${
      category ? `category=${category}&` : ""
    }${sdate ? `start_date=${sdate}` : ""}${ldate ? `&end_date=${ldate}` : ""}`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response.statusText) {
        setData(response.data);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const getblogcategory = async () => {
    try {
      let response = await axios.get(`${imageBaseUrl}/blog/category/`);
      if (response.statusText) {
        setCategory(response.data);
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleFilterByCategory = (category, startDate, endDate) => {
    getblogdata(
      category,
      startDate
        ? new Date(startDate)
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-")
        : "",
      endDate
        ? new Date(endDate)
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-")
        : "",
      endDate
    );
  };

  useEffect(() => {
    (async () => {
      let data = await getblogcategory();
      if (data) {
        getblogdata();
        // setSearchCategory(data[0]?.id);
      }
    })();
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      <div className="services_pg_wrp">
        <section className="Diagnostic_tests_wrp">
          <div className="container">
            <div className="Diagnostic_tests_inn_wrp">
              <div className="d-flex justify-content-between align-items-center">
                <h3>All Blog</h3>
                <div className="filter d-flex gap-2">
                  <Form.Select
                    className="text-capitalize"
                    onChange={(e) => {
                      setSearchCategory(e.target.value);
                      handleFilterByCategory(
                        e.target.value,
                        startDate,
                        endDate
                      );
                    }}
                  >
                    <option className="text-capitalize" value={""}>
                      All
                    </option>
                    {category.map((item, id) => {
                      return (
                        <option
                          className="text-capitalize"
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>

                  <div className="date_filter d-flex gap-2  align-items-center">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        handleFilterByCategory(searchCategory, date, endDate);
                      }}
                      selectsStart
                      todayButton="Today"
                      startDate={startDate}
                      endDate={endDate}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      maxDate={addDays(new Date(), 0)}
                      placeholderText="Start Date"
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        handleFilterByCategory(searchCategory, startDate, date);
                      }}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      minDate={addDays(new Date(startDate), 1)}
                      maxDate={addDays(new Date(), 0)}
                      placeholderText="End Date"
                    />
                  </div>
                  {/* <CiFilter className="fs-3 fw-bold" /> */}
                </div>
              </div>
              <div className="row">
                {data &&
                  data?.all_data?.map((item, i) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 services_cards"
                        key={i}
                      >
                        <div className="tests_cards">
                          <div className="product_image_wrp">
                            <img src={`${imageBaseUrl}${item?.image}`} alt="" />
                          </div>
                          <div>
                            <h3>{item?.title}</h3>
                            <div
                              className="desc"
                              dangerouslySetInnerHTML={{
                                __html: item?.description_ext,
                              }}
                            ></div>
                          </div>
                          <Dropdown className="list_options">
                            <Dropdown.Toggle id="list_options">
                              &#8230;
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setModalPlusIdPassHandler(item)}
                              >
                                Update
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => removeDataHandler(item)}
                              >
                                Remove
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      </div>
      <UpdateBlog
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedBlog={selectedBlog}
        getblogdata={getblogdata}
      />
      <RemoveBlogModel
        data={currItem}
        show={removeDiseaseModal}
        updatedata={getblogdata}
        onHide={() => setRemoveDiseaseModal(false)}
      />
    </>
  );
};

export default AllBlog;
