import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { GeneralInfoForm } from "./Forms";
// import { useEffect, useState, useRef } from "react";
// import axios from "axios";
// import Button from "react-bootstrap/Button";
// import { Card, Form } from "@themesberg/react-bootstrap";
// import { toast } from "react-toastify";

const MyVerticallyCenteredModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="diseases_edit_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <GeneralInfoForm
            hideModal={props.onHide}
            updatedata={props.getprodcutdata}
            data={props?.selectedproduct}
            keyword="update"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyVerticallyCenteredModal;
