import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import TransactionsTable from "./Tables";
import { Button, ButtonGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";

const WhatsAppUserTable = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [filter, setFilter] = useState("");
  const csvRef = useRef(0);
  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    (async () => {
      let access_token = localStorage.getItem("token");
      setLoader(true);
      const res = await axios.get(`${baseUrl}/counter/${filter}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (res?.status === 200) {
        setLoader(false);
        setData(res.data);
      }

      if (res?.code === "ERR_BAD_REQUEST") {
      }
    })();
  }, [filter]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>Whats App Data</h3>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-toolbar mb-2 mb-md-0">
            <CSVLink
              headers={[
                { label: "Date and Time", key: "date_and_time" },
                { label: "Location", key: "location" },
              ]}
              data={data.current_counter || []}
              filename={`whats_app_${filter.replace("/", "")}.csv`}
              className="hidden"
              ref={csvRef}
              target="_blank"
            />
            <ButtonGroup>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  // setCsvData(data.current_counter);
                  setTimeout(() => {
                    csvRef.current.link.click();
                  }, 500);
                }}
              >
                Export Csv
              </Button>
            </ButtonGroup>
          </div>
          <Button className="mx-2" variant="outline-primary" size="sm">
            Total Click: {data.count}
          </Button>
          <select onChange={handleFilter}>
            <option value="">All</option>
            <option value="today/">Today</option>
            <option value="current_week/">Weekly</option>
            <option value="current_month/">Monthly</option>
            <option value="current_year/">Yearly</option>
          </select>
        </div>
      </div>
      <TransactionsTable filter={filter} />
    </>
  );
};

export default WhatsAppUserTable;
