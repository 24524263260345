import React, { useEffect } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Form, Button } from "react-bootstrap";
import { useState } from "react";
import TransactionsTable from "./Tables";
import axios from "axios";
// import { useToast } from "react-toastify";

const ScreeningAdd = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  let access_token = localStorage.getItem("token");

  // const {} = useToast();

  const handleDelete = (id) => {
    setIsLoading(true);
    const res = axios.delete(`${baseUrl}/question-bank/${id}/`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    if (res?.status === 204) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      setIsLoading(true);
      const res = await axios.post(
        `${baseUrl}/question-bank/`,
        { question },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      // console.log(res);

      if (res?.status === 201) {
        setLoader(false);
        setIsLoading(false);
        // addToast("Added Successfully!", { appearence: "success" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      setLoader(true);
      setIsLoading(false);
      let access_token = localStorage.getItem("token");
      const res = await axios.get(`${baseUrl}/question-bank/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (res?.status === 200) {
        setIsOpenModal(false);
        setLoader(false);
        setIsLoading(false);
        setData(res.data);
      }
    })();
  }, [baseUrl, isLoading]);

  return (
    <>
      <div className="screening_add_wrp">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <h3 className="mb-0">Screening Questions</h3>

          <button
            onClick={() => setIsOpenModal(true)}
            className="add_btn btn btn-outline-dark px-3"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>

        <TransactionsTable
          handleDelete={handleDelete}
          loader={loader}
          transactions={data}
        />

        <Modal
          onHide={() => setIsOpenModal(!isOpenModal)}
          show={isOpenModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Question
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <div className="w-100 my-3">
                <textarea
                  rows={5}
                  className="form-control"
                  placeholder="Enter Question"
                  onChange={(e) => setQuestion(e.target.value)}
                  name="question"
                  value={question}
                  id="question"
                ></textarea>
              </div>

              {isLoading ? (
                <Button disabled variant="primary" type="submit">
                  Loading
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ScreeningAdd;
