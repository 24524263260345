import React, { useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Card } from "@themesberg/react-bootstrap";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Modal } from "react-bootstrap";

const TransactionsTable = ({ transactions, loader, handleDelete }) => {
  const TableRow = (props) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const { id, question } = props.transactions;

    return (
      <>
        <tr>
          <td>
            <span className="fw-normal">{props.index + 1}</span>
          </td>
          <td>
            <span className="fw-normal">{question}</span>
          </td>

          <td>
            <span
              onClick={() => setIsOpenModal(!isOpenModal)}
              className="fw-normal"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </td>

          <Modal
            onHide={() => setIsOpenModal(!isOpenModal)}
            show={isOpenModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center flex-column">
                <h4>Are you sure you want to delete this question?</h4>
                <button
                  onClick={() => handleDelete(id)}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </tr>
      </>
    );
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      {!loader ? (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">Sn.</th>
                  <th className="border-bottom">Question</th>
                  <th className="border-bottom">Action</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions?.length > 0 ? (
                  transactions?.map((t, index) => (
                    <TableRow
                      key={`transaction-${t?.id}`}
                      transactions={t}
                      index={index}
                    />
                  ))
                ) : (
                  <tr>
                    <td>
                      <span className="fw-normal no_item_found">
                        No item found
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default TransactionsTable;
