import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, Form, Button, Col } from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import JoditEditor from "jodit-react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { Modal } from "react-bootstrap";
import EditCity from "./editCity";

const AddCity = (props) => {
  let access_token = localStorage.getItem("token");

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const imageBaseUrl = process.env.REACT_APP_API_BASE_IMAGE_URL;

  const [modalShow, setModalShow] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeItemID, setActiveItemID] = useState(0);
  const [city, setCity] = useState(props?.data?.name);
  const [value, setvalue] = useState("");
  const [loader, setLoader] = useState(false);

  const [error, setError] = useState({
    city: "",
  });

  const [data, setData] = useState();

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      let formData = new FormData();

      if (props?.keyword === "name") {
        if (props.data.city !== city) {
          formData.append("name", city);
        }
      } else {
        formData.append("name", city);
      }

      let response = await axios({
        method: `${props?.keyword === "update" ? "patch" : "post"}`,
        url: `${
          props?.keyword === "update"
            ? `${imageBaseUrl}/hospital/city/${props?.data?.id}/`
            : `${imageBaseUrl}/hospital/city/`
        }`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response.data) {
        setLoader(false);
        toast.success("Your Post has been saved", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getCityData();
        if (props?.keyword !== "update") {
          setCity([]);
        } else {
          props.updatedata();
          props.hideModal();
        }
      } else {
        setLoader(false);
        throw new Error(response.statusText);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const inputClickHandler = (event, val) => {
    const { name, value } = event.target;
    setvalue(val);
    switch (name) {
      case "city":
        if (value === "") {
          setError({ ...error, city: "Title can not be empty" });
        } else {
          setError({ ...error, city: "" });
        }
        setCity(value);
        break;
      default:
        break;
    }
  };

  const getCityData = async () => {
    try {
      let response = await axios({
        method: "get",
        url: `${imageBaseUrl}/hospital/city/`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response.statusText) {
        setData(response.data);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const setModalPlusIdPassHandler = (item) => {
    setModalShow(true);
    setSelectedBlog(item);
  };
  useEffect(() => {
    getCityData();
  }, []);

  const deleteCityHandler = async (itemId) => {
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      let response = await axios({
        method: "delete",
        url: `${imageBaseUrl}/hospital/city/${itemId}/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response) {
        setLoader(false);
        setIsOpenModal(false)
        toast.success("Category Deleted", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getCityData();
      } else {
        setLoader(false);
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const getblogdata = async () => {
    try {
      let response = await axios.get(`${imageBaseUrl}/hospital/city/`);
      if (response.statusText) {
        setData(response.data);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <h3>Add City</h3>
      </div>
      <Card border="light" className="bg-white shadow-sm mb-4 addblog_page_wrp">
        <Card.Body>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City"
                name="city"
                required
                isInvalid={error.city}
                isValid={city}
                value={city}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {props?.keyword !== "update" ? (
        <div className="services_pg_wrp all_category_wrp">
          <section className="Diagnostic_tests_wrp">
            <div className="container">
              <div className="Diagnostic_tests_inn_wrp">
                <div>
                  <h3>All City</h3>
                </div>
                {data &&
                  data?.map((item, i) => {
                    return (
                      <div className="row exist_category" key={i}>
                        <div className="col-6">
                          <h5>{item?.name}</h5>
                        </div>
                        <div className="col-6 d-flex justify-content-end edit_option">
                          <button
                            className="edit_category"
                            onClick={() => setModalPlusIdPassHandler(item)}
                          >
                            Edit
                          </button>
                          <button
                            className="delete_category"
                            onClick={() => {
                              setIsOpenModal(!isOpenModal);
                              setActiveItemID(item.id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      <EditCity
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedBlog={selectedBlog}
        getblogdata={getblogdata}
      />

      <Modal
        onHide={() => setIsOpenModal(!isOpenModal)}
        show={isOpenModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h4>Are You Want to Delete?</h4>
            <button
              onClick={() => deleteCityHandler(activeItemID)}
              className="btn btn-primary"
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCity;
