import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import './diseases.scss';
import { toast } from "react-toastify";
import axios from "axios";

const RemoveProductModal = (props) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [loader, setLoader] = useState(false);

  const deleteProductHandler = async () => {
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      let response = await axios({
        method: "delete",
        url: `${baseUrl}/product/${props?.data}/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response) {
        setLoader(false);
        toast.success("Product Deleted", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.updatedata();
        props.onHide();
      } else {
        setLoader(false);
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="remove_disease_modal diseases_edit_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2> Delete Product </h2>
        <p> Are you sure you want to delete this Product?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>No</Button>
        <Button onClick={() => deleteProductHandler()}>Yes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveProductModal;
