import React from 'react';
import { useState, useRef } from "react";
import './get-support.scss';
import PropagateLoader from "react-spinners/PropagateLoader";
import { Button, ButtonGroup } from "@themesberg/react-bootstrap";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import GetSupportTable from './getSupportTable';

const headers = [
    { label: "Id", key: "id" },
    { label: "NAME", key: "name" },
    { label: "EMAIL", key: "email" },
    { label: "PHONE-NUMBER", key: "phone" },
    { label: "DESCRIPTION", key: "description" },
  ];

const GetSupport = () => {

const [loader, setLoader] = useState(false);
const [csvData, setCsvData] = useState([]);
const csvDownloadRef = useRef(0);

const baseUrl = process.env.REACT_APP_API_BASE_URL;


const fetchDataToDownload = async () => {
    try {
      let access_token = localStorage.getItem("token");
      setLoader(true);
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      };
      const response = await fetch(`${baseUrl}/get-support/?`,config);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        setCsvData(data.all_data);
        setTimeout(() => {
          csvDownloadRef.current.link.click();
        }, 500);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoader(false);
    }
  };

  return (
   <>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div clnextActiveItemassName="d-block mb-4 mb-md-0">
          <h3>Get Support</h3>
        </div>
        <div style={{ textAlign: "center" }}>
          <PropagateLoader
            color="#4A5073"
            loading={loader}
            size={15}
            aria-label="Loading Spinner"
          />
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <CSVLink
            headers={headers}
            data={csvData}
            filename="getsupport.csv"
            className="hidden"
            ref={csvDownloadRef}
            target="_blank"
          />
          <ButtonGroup>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={fetchDataToDownload}
            >
              Export Csv
            </Button>
          </ButtonGroup>
        </div>
    </div>
    <GetSupportTable />
   </>
  )
}

export default GetSupport;