import React from "react";
import { Nav, Card, Table, Pagination } from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import PropagateLoader from "react-spinners/PropagateLoader";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";
import { useState, useEffect } from "react";
const APP_URL = process.env.API_BASE_URL;

const TransactionsTable = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState("");
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const [activeItem, setActiveItem] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPagesCount] = useState(1);
  const [disablePrev, setDisablePrev] = useState(false);
  const [totalEntries, setTotalEntriesCount] = useState(1);
  const [withIcons, setWithIcons] = useState(false);
  const totalTransactions = transactions?.length;

  //pagination code Starting here................
  const onPrevItem = () => {
    const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
    setActiveItem(prevActiveItem);
  };

  const onNextItem = (totalPages) => {
    const nextActiveItem =
      activeItem === totalPages ? activeItem : activeItem + 1;
    setActiveItem(nextActiveItem);
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    const isItemActive = activeItem === number;

    const handlePaginationChange = () => {
      setActiveItem(number);
    };

    items.push(
      <Pagination.Item
        active={isItemActive}
        key={number}
        onClick={handlePaginationChange}
      >
        {number}
      </Pagination.Item>
    );
  }

  //pagination code ended here................

  const getAppointmentListData = async (pageNum = 1) => {
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      };
      let response = await fetch(
        "https://api.atulyahearing.com/api/book-an-appointment/?" +
          new URLSearchParams({ page: pageNum }),
        config
      );
      let data = await response.json();
      if (!response.ok) {
        console.log("responsesss", response);
        throw new Error(response.statusText);
      } else {
        setTransactions(data?.data);
        setTotalEntriesCount(data?.total_contacts);
        let parseCurrentPage = parseInt(data?.current_page);
        setCurrentPage(parseCurrentPage);
        setTotalPagesCount(data?.total_page);
      }
      setLoader(false);
    } catch (error) {
      setError(error);
      console.log("error Appointment page error", error);
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const getAppointmentListDataWithSearch = async (
    keyword = "",
    pageNumber = 1,
    filter
  ) => {
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      };
      let url = "https://api.atulyahearing.com/api/search-appointment";
      let response;
      if (filter === 11) {
        response = await fetch(
          `${url}/?address=${keyword}&page_no=${pageNumber}`,
          config
        );
      } else if (filter === 22) {
        response = await fetch(
          `${url}/?reason=${keyword}&page_no=${pageNumber}`,
          config
        );
      }

      let data = await response.json();
      if (!response.ok) {
        console.log("responsesss", response);
        throw new Error(response.statusText);
      } else {
        setTransactions(data?.data);
        setTotalEntriesCount(data?.total_contacts);
        let parseCurrentPage = parseInt(data?.current_page);
        setCurrentPage(parseCurrentPage);
        setTotalPagesCount(data?.total_page);
      }
      setLoader(false);
    } catch (error) {
      setError(error);
      console.log("error Appointment page error", error);
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    if (props.searchWithCity.length || props.searchWithReason.length) {
      if (props.searchWithCity.length > 0) {
        getAppointmentListDataWithSearch(props?.searchWithCity, activeItem, 11);
      } else if (props.searchWithReason.length > 0) {
        getAppointmentListDataWithSearch(
          props?.searchWithReason,
          activeItem,
          22
        );
      }
    } else {
      getAppointmentListData(activeItem);
    }
    return () => {
      setState({});
    };
  }, [props.searchWithCity.length, props.searchWithReason.length, activeItem]);

  const TableRow = (props) => {
    const { name, date_and_time, email, mobile, address } = props.transactions;

    return (
      <tr>
        <td>
          {/* <Card.Link as={Link} to={routerMaping.Invoice.path} className="fw-normal">
            {invoiceNumber}
          </Card.Link> */}
          <span className="fw-normal">{props.index + 1}</span>
        </td>
        <td>
          <span className="fw-normal">{name}</span>
        </td>
        <td>
          <span className="fw-normal">{date_and_time}</span>
        </td>
        <td>
          <span className="fw-normal">{email}</span>
        </td>
        <td>
          {/* <span className="fw-normal">${parseFloat(price).toFixed(2)}</span> */}
          <span className="fw-normal">{mobile}</span>
        </td>
        <td>
          <span className="fw-normal">
            {address ? address : "Not Mentioned!"}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Sn.</th>
                <th className="border-bottom">Name</th>
                <th className="border-bottom">Date & Time</th>
                <th className="border-bottom">Email</th>
                <th className="border-bottom">Mobile Number</th>
                <th className="border-bottom">Address</th>
              </tr>
            </thead>
            <tbody>
              {transactions && transactions?.length > 0 ? (
                transactions?.map((t, index) => (
                  <TableRow
                    key={`transaction-${t?.id}`}
                    transactions={t}
                    index={index}
                  />
                ))
              ) : (
                <tr>
                  <td>
                    <span className="fw-normal no_item_found">
                      No item found
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination size="md" className="mt-3">
                <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
                  {withIcons ? (
                    <ChevronDoubleLeftIcon className="icon icon-xs" />
                  ) : (
                    "Previous"
                  )}
                </Pagination.Prev>
                {totalPages > 5 && activeItem > 3 ? (
                  <> {items.slice(activeItem - 3, activeItem + 2)}</>
                ) : (
                  <>{items.slice(0, 5)}</>
                )}
                <Pagination.Next onClick={() => onNextItem(totalPages)}>
                  {withIcons ? (
                    <ChevronDoubleRightIcon className="icon icon-xs" />
                  ) : (
                    "Next"
                  )}
                </Pagination.Next>
              </Pagination>
              <br />
            </Nav>
            <small className="fw-bold">
              Showing <b>{10 * activeItem - (10 - transactions.length)}</b> out
              of <b>{totalEntries}</b>
              Entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export default TransactionsTable;
