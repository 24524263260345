import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, Form, Button } from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";

export const GeneralInfoForm = (props) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const imageBaseUrl = process.env.REACT_APP_API_BASE_IMAGE_URL;
  const history = useHistory();
  const attachmentInputRef = useRef();
  const hearingAttInputRef = useRef();
  const [placeholder, setPlaceholder] = useState("Start typings...");
  const [description, setDescription] = useState(props?.data?.description);
  const [metaTitle, setMetaTitle] = useState(props?.data?.meta_title);
  const [metaDescription, setMetaDescription] = useState(
    props?.data?.meta_description
  );
  const [title, setTitle] = useState(props?.data?.title);
  const [slug, setSlug] = useState(props?.data?.slug);
  const editor = useRef(null);
  const [selectedBannerFile, setSelectedBannerFile] = useState(false);

  const [specification, setSpecification] = useState(
    props.data ? JSON.parse(props.data.product_specification) : [{}, {}, {}]
  );
  const [hearingAidBannerImg, setHearingAidBannerImg] = useState({
    name: "",
    value: "",
  });
  const [miniatureAidsHead, setMiniatureAidsHead] = useState(
    props?.data?.miniature_aids_head
  );
  const [miniatureAidsDetail, setMiniatureAidsDetail] = useState(
    props?.data?.miniature_aids_detail
  );

  let FindOutContent = "";
  if (props.keyword === "update") {
    FindOutContent = `<p>${props?.data?.Find_out_detail}</p>`;
  }
  const [findOutDetail, setFindOutDetail] = useState(FindOutContent);

  const [technologyImplementHead, setTechnologyImplementHead] = useState(
    props?.data?.technology_implement_head
  );
  const [technologyImplementDetail, setTechnologyImplementDetail] = useState(
    props?.data?.technology_implement_detail
  );
  const [maintenanceHead, setMaintenanceHead] = useState(
    props?.data?.maintenance_head
  );
  const [maintenanceDetail, setMaintenanceDetail] = useState(
    props?.data?.maintenance_detail
  );
  const [batteryLevelHead, setBatteryLevelHead] = useState(
    props?.data?.battery_level_head
  );
  const [batteryLevelDetail, setBatteryLevelDetail] = useState(
    props?.data?.battery_level_detail
  );

  const [state, setState] = useState({});
  const [selectedFile, setSelectedFile] = useState(false);
  const [attactment, setAttachment] = useState({
    name: "",
    value: "",
  });
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    title: "",
    description: "",
    attachment: "",
    dropdown: "",
    hearingAidBannerImg: "",
    miniatureAidsHead: "",
    setMiniatureAidsDetail: "",
    technologyHead: "",
    technologyDetail: "",
    volumeAdjustmentsHead: "",
    volumeAdjustmentsDetail: "",
    lucidityHead: "",
    lucidityDetail: "",
    findOutDetail: "",
    technologyImplementHead: "",
    maintenanceHead: "",
    batteryLevelHead: "",
    batteryLevelDetail: "",
  });

  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: placeholder || "Start typings...",
    };
  }, [placeholder]);

  const inputClickHandler = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "attachment":
        if (value === "" || value == null || !value) {
          if (!attactment) {
            setError({ ...error, attachment: "can not be empty" });
          }
        } else {
          setError({ ...error, attachment: "" });
        }
        if (value) {
          setAttachment({
            name: event.target.files[0].name,
            value: event.target.files[0],
          });
          setSelectedFile(true);
        }
        break;
      case "title":
        if (value === "") {
          setError({ ...error, title: "Title can not be empty" });
        } else {
          setError({ ...error, title: "" });
        }
        setTitle(value);
        setSlug(value.split(" ").join("-"));
        break;
      case "slug":
        if (value === "") {
          setError({ ...error, slug: "Title can not be empty" });
        } else {
          setError({ ...error, slug: "" });
        }
        setSlug(value);
        break;
      case "description":
        if (value === "") {
          setError({ ...error, description: "Description can not be empty" });
        } else {
          setError({ ...error, description: "" });
        }
        setDescription(value);
        break;
      case "metaTitle":
        setMetaTitle(value);
        break;
      case "metaDescription":
        setMetaDescription(value);
        break;
      case "dropdown":
        if (value === "") {
          setError({ ...error, dropdown: "Box number can not be empty" });
        } else {
          setError({ ...error, dropdown: "" });
        }
        console.log("dsetBoxNumbersetBoxNumbersetBoxNumber", value);
        break;
      case "hearingAidBannerImg":
        if (value === "" || value == null || !value) {
          if (!hearingAidBannerImg) {
            setError({
              ...error,
              hearingAidBannerImg: "image can not be empty",
            });
          }
        } else {
          setError({ ...error, hearingAidBannerImg: "" });
        }
        if (value) {
          setHearingAidBannerImg({
            name: event.target.files[0].name,
            value: event.target.files[0],
          });
          setSelectedBannerFile(true);
        }
        break;
      case "miniatureAidsHead":
        if (value === "") {
          setError({
            ...error,
            miniatureAidsHead: "Box number can not be empty",
          });
        } else {
          setError({ ...error, miniatureAidsHead: "" });
        }
        setMiniatureAidsHead(value);
        break;
      case "miniatureAidsDetail":
        if (value === "") {
          setError({
            ...error,
            miniatureAidsDetail: "Box number can not be empty",
          });
        } else {
          setError({ ...error, miniatureAidsDetail: "" });
        }
        setMiniatureAidsDetail(value);
        break;
      case "technologyImplementHead":
        if (value === "") {
          setError({
            ...error,
            technologyImplementHead: "This field can not be empty",
          });
        } else {
          setError({ ...error, technologyImplementHead: "" });
        }
        setTechnologyImplementHead(value);
        break;
      case "technologyImplementDetail":
        if (value === "") {
          setError({
            ...error,
            technologyImplementDetail: "This field can not be empty",
          });
        } else {
          setError({ ...error, technologyImplementDetail: "" });
        }
        setTechnologyImplementDetail(value);
        break;

      default:
        break;
    }
  };

  const bannerDataInputClickHandler = (event) => {
    let parser = new DOMParser();
    const doc = parser.parseFromString(event, "text/html");
    let editorWrp = document.querySelector("#about-data-editor .jodit-wysiwyg");
    const childN = editorWrp.childNodes;
    let emptyParaLength = 0;
    if (childN.length === 1) {
      const findWrp = doc.getElementsByTagName("p")[0];
      if (findWrp.innerHTML !== "<br>") {
        setFindOutDetail(event);
        setError({ ...error, findOutDetail: "" });
      } else {
        setError({ ...error, findOutDetail: "Please enter Find Out Detail" });
      }
    } else {
      for (var i = 0; i < childN.length; i++) {
        if (childN[i].innerHTML !== "<br>") {
          emptyParaLength += 1;
        }
      }
      if (emptyParaLength >= 1) {
        setFindOutDetail(event);
        setError({ ...error, findOutDetail: "" });
      } else {
        setError({ ...error, findOutDetail: "Please enter Find Out Detail" });
      }
    }
  };

  const handleSpecification = (e, index) => {
    const list = [...specification];
    list[index][e.target.name] = e.target.value;
    setSpecification(list);
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      setLoader(true);
      let access_token = localStorage.getItem("token");
      let formData = new FormData();

      if (props?.keyword === "update") {
        if (props?.data?.title !== title) {
          formData.append("title", title);
        }
        if (props?.data?.slug !== slug) {
          formData.append("slug", slug);
        }
        if (attactment?.value && attactment.name) {
          formData.append("image", attactment?.value, attactment?.name);
        }
        if (props.data.meta_description !== metaDescription) {
          formData.append("meta_description", metaDescription);
        }
        if (props.data.meta_title !== metaTitle) {
          formData.append("meta_title", metaTitle);
        }
        if (props.data.description !== description) {
          formData.append("description", description);
        }
        if (hearingAidBannerImg?.value && hearingAidBannerImg.name) {
          formData.append(
            "banner_image",
            hearingAidBannerImg?.value,
            hearingAidBannerImg?.name
          );
        }
        if (props.data.miniature_aids_head !== miniatureAidsHead) {
          formData.append("miniature_aids_head", miniatureAidsHead);
        }
        if (props.data.miniature_aids_detail !== miniatureAidsDetail) {
          formData.append("miniature_aids_detail", miniatureAidsDetail);
        }
        // if (props.data.Find_out_detail !== findOutDetail) {
        //   formData.append("find_out_detail", "<>Hello</>");
        // }
        if (
          props.data.product_specification !== JSON.stringify(specification)
        ) {
          formData.append(
            "product_specification",
            JSON.stringify(specification)
          );
        }
      } else {
        formData.append("image", attactment?.value, attactment?.name);
        formData.append("title", title);
        formData.append("slug", slug);
        formData.append("description", description);
        formData.append("meta_title", metaTitle);
        formData.append("meta_description", metaDescription);
        formData.append(
          "banner_image",
          hearingAidBannerImg?.value,
          hearingAidBannerImg?.name
        );
        formData.append("miniature_aids_head", miniatureAidsHead);
        formData.append("miniature_aids_detail", miniatureAidsDetail);
        formData.append("product_specification", JSON.stringify(specification));
        formData.append("find_out_detail", "<p>hello</p>");
      }

      let response = await axios({
        method: `${props?.keyword === "update" ? "patch" : "post"}`,
        url: `${
          props?.keyword === "update"
            ? `${baseUrl}/product/${props?.data?.slug}/`
            : `${baseUrl}/product/`
        }`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;boundary=`---MyBoundaryIs123`",
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.data) {
        setLoader(false);
        history.push("/products/all-products");
        toast.success("Your Post has been saved", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (props?.keyword !== "update") {
          setSelectedFile(false);
          setSelectedBannerFile(false);
          setDescription("");
          setTitle("");
          setSlug("");
          setAttachment({
            name: "",
            value: "",
          });
          setHearingAidBannerImg({
            name: "",
            value: "",
          });
          setMiniatureAidsHead("");
          setMiniatureAidsDetail("");
        } else {
          props.updatedata();
          props.hideModal();
        }
      } else {
        setLoader(false);
        throw new Error(response.statusText);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <PropagateLoader
          color="#4A5073"
          loading={loader}
          size={15}
          aria-label="Loading Spinner"
        />
      </div>
      <br />
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group>
              <Form.Label>Choose Product Image</Form.Label>
              <Form.Control
                type="file"
                name="attachment"
                accept="image/png, image/jpeg"
                required={props?.data?.image ? false : true}
                ref={attachmentInputRef}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
              {error.attachment.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  Please choose a product image.
                </Form.Control.Feedback>
              ) : (
                <Form.Control.Feedback type="valid">
                  Looks good!
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {selectedFile ? (
              <div style={{ padding: "2px", margin: "2px" }}>
                <img
                  style={{
                    maxWidth: "60%",
                    border: "2px solid #ede7e7",
                    borderRadius: "10px",
                  }}
                  src={window.URL.createObjectURL(attactment?.value)}
                  alt="productImage"
                />
              </div>
            ) : (
              <img src={`${props?.data?.image}`} alt="" />
            )}
            <Form.Group className="mb-3">
              <Form.Label>Product Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                name="title"
                required
                isInvalid={error.title}
                isValid={title}
                value={title}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
              {error.title && (
                <Form.Control.Feedback type="isInvalid">
                  Please enter a valid title.
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="valid">
                Looks good!
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Product Slug</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter slug"
                name="slug"
                required
                onBlur={() => {
                  setSlug(slug.split(" ").join("-"));
                }}
                isInvalid={error.slug}
                isValid={slug}
                value={slug}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
              {error.slug && (
                <Form.Control.Feedback type="isInvalid">
                  Please enter a valid slug.
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="valid">
                Looks good!
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Product Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="description"
                value={description}
                placeholder="description"
                required
                isInvalid={error.description}
                isValid={description}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Meta Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Meta title"
                name="metaTitle"
                value={metaTitle}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Meta Description"
                name="metaDescription"
                value={metaDescription}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Miniature Aids Head</Form.Label>
              <Form.Control
                type="text"
                placeholder="Miniature Aids Head"
                name="miniatureAidsHead"
                required
                isInvalid={error.miniatureAidsHead}
                isValid={miniatureAidsHead}
                value={miniatureAidsHead}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Miniature Aids Detail</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Enter Detail"
                name="miniatureAidsDetail"
                required
                // isInvalid={error.miniatureAidsDetail}
                isValid={miniatureAidsDetail}
                value={miniatureAidsDetail}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Hearing Aid Banner Image</Form.Label>
              <Form.Control
                type="file"
                name="hearingAidBannerImg"
                accept="image/png, image/jpeg"
                required={props?.data?.banner_image ? false : true}
                ref={hearingAttInputRef}
                onChange={(e) => {
                  inputClickHandler(e);
                }}
              />
              {error.hearingAidBannerImg.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  Please Choose Banner Image.
                </Form.Control.Feedback>
              ) : (
                <Form.Control.Feedback type="valid">
                  Looks good!
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {selectedBannerFile ? (
              // <div style={{ padding: "2px", margin: "2px" }}>
              <img
                style={{
                  maxWidth: "60%",
                  border: "2px solid #ede7e7",
                  borderRadius: "10px",
                }}
                src={window.URL.createObjectURL(hearingAidBannerImg?.value)}
                alt="productImage"
              />
            ) : (
              // </div>
              <img src={props?.data?.banner_image} alt="" />
            )}
            <h3>Specification</h3>
            <h4>Tab 1</h4>
            <div className="wrp my-3">
              <Form.Group className="mb-3">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Technology Head"
                  name="heading"
                  required
                  // isInvalid={error.technologyHead}
                  // isValid={true}
                  value={specification[0]?.heading}
                  onChange={(e) => handleSpecification(e, 0)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Technology Sub-Head"
                  name="subheading"
                  required
                  // isInvalid={error.technologyHead}
                  // isValid={"technologyHead"}
                  value={specification[0]?.subheading}
                  onChange={(e) => handleSpecification(e, 0)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="description"
                  value={specification[0]?.description}
                  placeholder="Enter Technology Detail"
                  required
                  // isInvalid={error.technologyDetail}
                  // isValid={"technologyDetail"}
                  onChange={(e) => handleSpecification(e, 0)}
                />
              </Form.Group>
            </div>
            <h4>Tab 2</h4>
            <div className="wrp my-3">
              <Form.Group className="mb-3">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Technology Head"
                  name="heading"
                  required
                  // isInvalid={error.technologyHead}
                  // isValid={true}
                  value={specification[1]?.heading}
                  onChange={(e) => handleSpecification(e, 1)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Technology Sub-Head"
                  name="subheading"
                  required
                  // isInvalid={error.technologyHead}
                  // isValid={"technologyHead"}
                  value={specification[1]?.subheading}
                  onChange={(e) => handleSpecification(e, 1)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="description"
                  value={specification[1]?.description}
                  placeholder="Enter Technology Detail"
                  required
                  // isInvalid={error.technologyDetail}
                  // isValid={"technologyDetail"}
                  onChange={(e) => handleSpecification(e, 1)}
                />
              </Form.Group>
            </div>
            <h4>Tab 3</h4>
            <div className="wrp my-3">
              <Form.Group className="mb-3">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Technology Head"
                  name="heading"
                  required
                  // isInvalid={error.technologyHead}
                  // isValid={false}
                  value={specification[2]?.heading}
                  onChange={(e) => handleSpecification(e, 2)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Technology Sub-Head"
                  name="subheading"
                  required
                  // isInvalid={error.technologyHead}
                  // isValid={"technologyHead"}
                  value={specification[2]?.subheading}
                  onChange={(e) => handleSpecification(e, 2)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="description"
                  value={specification[2]?.description}
                  placeholder="Enter Technology Detail"
                  required
                  // isInvalid={error.technologyDetail}
                  // isValid={"technologyDetail"}
                  onChange={(e) => handleSpecification(e, 2)}
                />
              </Form.Group>
            </div>

            <Button variant="primary" className="mt-4 d-block" type="submit">
              {props?.keyword === "update" ? "Update" : "Submit"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
