export const Routes = {
  // pages
  Presentation: { path: "/" },
  Signin: { path: "/sign-in" },
  ContactUs: { path: "/contact-us" },
  Appointment: { path: "/appointment" },
  NewsLetter: { path: "/newsletter" },
  GetSupport: { path: "/get-support" },
  Partners: { path: "/partners" },
  About: { path: "/about" },
  Services: { path: "/products/add-product" },
  AllProducts: { path: "/products/all-products" },
  AddLocation: { path: "/locate-us/add" },
  AllLocation: { path: "/locate-us/all" },
  AddCity: { path: "/locate-us/add-city" },
  DashboardOverview: { path: "/dashboard/overview" },
  Transactions: { path: "/transactions" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },
  AddDiseases: { path: "/add-diseases/" },
  AllDiseases: { path: "/all-diseases/" },
  AddBlog: { path: "/blog/add-blog" },
  AllBlog: { path: "/blog/all-blog" },
  Category: { path: "/blog/category" },
  ScreeningAdd: { path: "/quick-screening/screening-add/" },
  UserScreening: { path: "/quick-screening/user-screening" },
  Banner: { path: "/banner-uploads" },
  WhatsApp: { path: "/whatsapp" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
