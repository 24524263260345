import React from "react";
import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  Button,
  ButtonGroup,
  InputGroup,
} from "@themesberg/react-bootstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

import TransactionsTable from "./Tables";
import { Container } from "react-bootstrap";

const headers = [
  { label: "Id", key: "id" },
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "CITY", key: "city" },
  { label: "MESSAGE", key: "message" },
  { label: "REASON", key: "reason" },
  { label: "DATE & TIME", key: "date_and_time" },
  { label: "EMAIL", key: "email" },
  { label: "MOBILE NUMBER", key: "mobile" },
];

export default () => {
  const csvDownloadRef = useRef(0);
  const [csvData, setCsvData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchWithCity, setSearch] = useState("");
  const [searchWithReason, setReason] = useState("");

  const fetchDataToDownload = async () => {
    try {
      let access_token = localStorage.getItem("token");
      setLoader(true);
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      };
      const response = await fetch(
        "https://api.atulyahearing.com/api/book-an-appointment/",
        config
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        setCsvData(data.all_data);
        setTimeout(() => {
          csvDownloadRef.current.link.click();
        }, 500);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    console.log("csvDAta", csvData);
  }, [csvData]);

  const handleSearchChange = (event) => {
    let fieldVal = event.target.value;
    setSearch(fieldVal);
  };
  // const handleReasonChange = (event) => {
  //   let fieldVal = event.target.value;
  //   setReason(fieldVal);
  // };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>Appointment</h3>
        </div>
        <div style={{ textAlign: "center" }}>
          <PropagateLoader
            color="#4A5073"
            loading={loader}
            size={15}
            aria-label="Loading Spinner"
          />
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <CSVLink
            headers={headers}
            data={csvData}
            filename="Appointment.csv"
            className="hidden"
            ref={csvDownloadRef}
            target="_blank"
          />
          <ButtonGroup>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={fetchDataToDownload}
            >
              Export Csv
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    name="searchWithCity"
                    defaultValue={searchWithCity}
                    onChange={handleSearchChange}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
          {/* <div className="d-flex align-items-center">
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Reason"
                    name="searchWithReason"
                    defaultValue={searchWithReason}
                    onChange={handleReasonChange}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </div> */}
        </div>
      </Container>
      <TransactionsTable
        searchWithCity={searchWithCity}
        searchWithReason={searchWithReason}
      />
    </>
  );
};
