// Here are more informations about the scroll restoration of React Router
// https://reactrouter.com/web/guides/scroll-restoration

import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default () => {
  const { pathname } = useLocation();
  const token = localStorage.getItem("token");
  const navigate = useHistory();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [pathname]);

  const parseJWT = (token) => {
    return JSON.parse(atob(token.split(".")[1]));
  };

  useEffect(() => {
    if (!token) return;
    const exp = parseJWT(token).exp;
    console.log(exp);
    if (exp * 1000 < Date.now()) {
      navigate.push("/");
    }
  }, [pathname]);

  return null;
};
