import React, { useState, useEffect } from 'react';
import './diseases.scss';
import Dropdown from "react-bootstrap/Dropdown";
import DiseasesEditModal from './diseases-edit-modal';
import RemoveDiseaseModal from './remove-modal';

import axios from 'axios';

const AllDiseases = () => {

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const imageBaseUrl = process.env.REACT_APP_API_BASE_IMAGE_URL;


  const [data, setData] = useState();
  const [updateModalShow, setUpdateModalShow] = React.useState(false);
  const [currItem, setCurrItem] = useState([]);
  const [removeDiseaseModal, setRemoveDiseaseModal] = React.useState(false);



  const updateDataHandler = (item) => {
    setUpdateModalShow(true)
    setCurrItem(item)
  }
    
  const removeDataHandler = (item) => {
    setRemoveDiseaseModal(true)
    setCurrItem(item)
  }


  const getDiseasesData = async () => {
    try {
      let response = await axios({
        method: "get",
        url: `${baseUrl}/add-disease/`,
      });
      if (response.statusText) {
        setData(response.data);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    getDiseasesData();
  }, []);

  return (
    <div className='add_diseases_wrp'>
      <h3 className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>Add Diseases</h3>
      <div className="row add_diseases_inn">
        {data &&
          data.map((item, i) => {
            return (
              <div
                className="col-lg-4 col-md-6 col-sm-12 services_cards"
                key={i}
              >
                <div className="tests_cards">
                  <div className="product_image_wrp ">
                    <div className='product_image' >
                       <img src={`${imageBaseUrl}${item?.banner_img}`} alt="" />
                    </div>
                  </div>
                  <div>
                    <h3>{item?.title}</h3>
                    <div className="desc" dangerouslySetInnerHTML={{__html: item?.about}}/>
                  </div>
                  <Dropdown className="list_options">
                    <Dropdown.Toggle id="list_options">
                      &#8230;
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={()=>updateDataHandler(item)} >Update</Dropdown.Item>
                      <Dropdown.Item onClick={() => removeDataHandler(item)} >Remove</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            );
          })}
      </div>
      <DiseasesEditModal
        data={currItem}
        show={updateModalShow}
        updatedata={getDiseasesData} 
        onHide={() => setUpdateModalShow(false)}
      />
      <RemoveDiseaseModal
          data={currItem}
          show={removeDiseaseModal}
          updatedata={getDiseasesData} 
          onHide={() => setRemoveDiseaseModal(false)}
        />
    </div>
  )
}

export default AllDiseases